import { defineMessages } from 'react-intl'

export const DockerOptionsMessages = defineMessages({
  cpusDescription: {
    defaultMessage: 'Selected {value} vCPUs',
    id: 'O8+0y3Iv',
    description: 'Description for the --cpus option.',
  },
  envDescription: {
    defaultMessage: 'Environment variable set {value}',
    id: 'QGlJkPR3',
    description: 'Description for the --env option.',
  },
  entrypointDescription: {
    defaultMessage: 'Entrypoint configured',
    id: '0uJaSLB5',
    description: 'Description for the --entrypoint option.',
  },
  exposeDescription: {
    defaultMessage: 'No impact',
    id: 'wks51a+C',
    description: 'Description for the --expose option.',
  },
  gpusDescription: {
    defaultMessage: 'Please select compatible GPUs in machine hardware options',
    id: 'f7RDLLzB',
    description: 'Description for the --gpus option.',
  },
  interactiveDescription: {
    defaultMessage:
      'Select a running instance in the Portal to get a terminal to your container. You may need to specify a command to ensure the container remains active. {learn_more}',
    id: 'DJbidVSF',
    description: 'Description for the --interactive option.',
  },
  logDriverDescription: {
    defaultMessage: 'Please define logging options in container monitoring options',
    id: 'iSe6Epb2',
    description: 'Description for the --log-driver option.',
  },
  logOptDescription: {
    defaultMessage: 'Please define logging options in container monitoring options',
    id: 'ItAV3Nic',
    description: 'Description for the --log-opt option.',
  },
  memoryDescription: {
    defaultMessage: 'Selected RAM {value}GB',
    id: 'XxBWutma',
    description: 'Description for the --memory option.',
  },
  nameDescription: {
    defaultMessage: 'Container name set to {value}',
    id: 'hOt2mqfF',
    description: 'Description for the --name option.',
  },
  noGpuConfiguredDescription: {
    defaultMessage: 'No GPU configured',
    id: '12c1Xw4F',
    description: 'Description for the --no-gpu option.',
  },
  notSupported: {
    defaultMessage: 'Not supported at this time',
    id: 'J4vXtpRU',
    description: 'General description for unsupported options',
  },
  portDescription: {
    defaultMessage:
      'Container gateway configured with port {value}. A domain name to access your container will be provided.',
    id: 'goLSucW7',
    description: 'Description for the --publish option.',
  },
  restartDescription: {
    defaultMessage: 'Restart policy set to -always',
    id: 'dZlHznfu',
    description: 'Description for the --restart option.',
  },
  rmDescription: {
    defaultMessage: 'All containers are removed once stopped on Salad',
    id: 'XD1xwc3s',
    description: 'Description for the --rm option when not supported.',
  },
  storageOptDescription: {
    defaultMessage: 'Selected disk space {value} GB',
    id: '6NKJclCj',
    description: 'Description for the --storage-opt option.',
  },
  ttyDescription: {
    defaultMessage:
      'Select a running instance in the Portal to get a terminal to your container. You may need to specify a command to ensure the container remains active. {learn_more}',
    id: '64JkKtAJ',
    description: 'Description for the --tty option.',
  },
  unknownFlagDescription: {
    defaultMessage: 'Unknown flag with value {value}',
    id: 'Icn1dJWa',
    description: 'General description for unsupported options',
  },
})
