import { ContainerGroupPriority } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { IntlShape } from 'react-intl'
import type { CheckboxCardOptions } from '../../../../components/forms/CheckboxCards/models'
import { GPUIcon } from '../../../../components/icons/GPUIcon'
import type { ClientGpuClass, ClientGpuOptions } from '../../../../features/gpuClasses/models'
import { getGpuClassPriceBasedOnPriority } from '../../../../utils/retrieveContainerGroupOptions'
import { EditGPUInputMessages } from '../../messages'
import { EditHardwareResourceCardContent } from '../EditHardwareResourceCardContent'

/**
 * Generates an array of GPU Options for the Edit GPU input field.
 *
 * @param intl The intl provider.
 * @param gpuOptions The GPU options the user has to select from.
 * @param isContainerGroupPriorityEnabled The flag indicating if the container group priority input is enabled.
 * @param priority The selected container group priority.
 */
export const generateEditGPUOptionCards = (
  intl: IntlShape,
  gpuOptions: ClientGpuOptions,
  isContainerGroupPriorityEnabled: boolean,
  priority: ContainerGroupPriority = ContainerGroupPriority.High,
): CheckboxCardOptions => {
  return gpuOptions && gpuOptions.length > 0
    ? gpuOptions.map((option) => {
        let price = option.price
        if (isContainerGroupPriorityEnabled) {
          price = getGpuClassPriceBasedOnPriority(option, priority)
        }
        return {
          content: option.name ? (
            <EditHardwareResourceCardContent
              costPerHour={price}
              Icon={<GPUIcon />}
              isHighDemand={option.isHighDemand}
              label={option.name}
            />
          ) : (
            <p className="text-base font-medium">{intl.formatMessage(EditGPUInputMessages.noneOptionLabel)}</p>
          ),
          value: option.id,
        }
      })
    : []
}

/**
 * A function that returns the gpu classes with details based on the gpu ids that the user has selected.
 *
 * @param gpuOptions The list of all available gpu options
 * @param gpuIds The list of gpu ids that the user has selected
 */
export const getGpuClassesById = (gpuOptions: ClientGpuOptions, gpuIds: string[] | undefined) => {
  let gpuClasses: ClientGpuClass[] = []
  gpuIds?.map((gpu) => {
    return gpuOptions?.map((gpuClass) => {
      return gpu === gpuClass.id ? gpuClasses.push(gpuClass) : undefined
    })
  })
  return gpuClasses
}

export const checkIfAllGpuClassesAreHighDemand = (gpuClasses: ClientGpuClass[] | undefined) => {
  return gpuClasses && gpuClasses.length > 0 && gpuClasses.every((gpu) => gpu?.isHighDemand)
}

/**
 * Filters the GPU options based on the selected filter by option.
 *
 * @param gpuOptions The list of all available gpu options
 * @param selectedFilterByOption The selected filter by option
 * @param filterByLessThan8GB The filter by the less than 8GB option
 * @param filterBy8GBPlus The filter by 8GB+ option
 * @param filterBy16GBPlus The filter by 16GB+ option
 * @param filterBy24GB The filter by 24GB option
 * @param query The search query
 * @returns The filtered GPU options
 */
export const filterGPUOptions = (
  gpuOptions: ClientGpuOptions,
  selectedFilterByOption: string | undefined,
  filterByLessThan8GB: string,
  filterBy8GBPlus: string,
  filterBy16GBPlus: string,
  filterBy24GB: string,
  query: string,
) =>
  gpuOptions?.filter((gpu) => {
    const filterByQuery = query.length > 0 ? gpu.name.toUpperCase().includes(query.toUpperCase()) : true
    const filterByOptions = (selectedFilter: string | undefined) => {
      const vram = gpu.name.match(/\d+ GB/) ? parseInt(gpu.name.match(/\d+ GB/)?.[0] || '0', 10) : null
      switch (selectedFilter) {
        case filterByLessThan8GB:
          return vram !== null && vram < 8
        case filterBy8GBPlus:
          return vram !== null && vram >= 8
        case filterBy16GBPlus:
          return vram !== null && vram >= 16
        case filterBy24GB:
          return vram !== null && vram === 24
        default:
          return true
      }
    }

    return filterByQuery && filterByOptions(selectedFilterByOption)
  })

/**
 * Sorts the GPU options based on the selected sort by option.
 *
 * @param gpuOptions
 * @param selectedSortByOption
 * @param sortByHighToLowOption
 * @returns
 */
export const sortGPUOptions = (
  gpuOptions: ClientGpuOptions,
  selectedSortByOption: string | undefined,
  sortByHighToLowOption: string,
) => {
  switch (selectedSortByOption) {
    case sortByHighToLowOption:
      gpuOptions?.sort((gpuA, gpuB) => {
        const vramA = parseInt(gpuA.name.match(/\d+ GB/)?.[0] || '0', 10)
        const vramB = parseInt(gpuB.name.match(/\d+ GB/)?.[0] || '0', 10)
        return vramB - vramA
      })
      break
  }

  return gpuOptions
}
