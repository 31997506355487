import { MessageDescriptor } from 'react-intl'
import { DockerOptionsMessages } from '../messages'
import { DockerParseResult } from '../models'
import {
  dockerParseCpusAction,
  dockerParseEnvAction,
  dockerParseExposeAction,
  dockerParseGpusAction,
  dockerParseLogDriverAction,
  dockerParseLogOptAction,
  dockerParseMemoryAction,
  dockerParseNameAction,
  dockerParsePortAction,
  dockerParseRestartAction,
  dockerParseStorageOptAction,
} from './DockerRunParsingActions'

export interface DockerOption {
  /* The function to execute when the option is found */
  action?: (value: string) => DockerParseResult
  /* Description of the option */
  description: MessageDescriptor
  /* Whether the option has no value */
  hasNoValue?: boolean
  /* Whether the option is recommended */
  isRecommended?: boolean
  /* Whether the option is supported */
  isSupported: boolean
  /* Whether the option is a warning */
  isWarning?: boolean
  /* Learn more link */
  learnMore?: string
  /* The names of the option */
  names: string[]
  /* Recommended description of the option */
  recommendedDescription?: MessageDescriptor
}

const supportedOptions: DockerOption[] = [
  {
    action: (value) => dockerParseCpusAction(value),
    description: DockerOptionsMessages.cpusDescription,
    isSupported: true,
    names: ['--cpus'],
  },
  {
    action: (value) => dockerParseEnvAction(value),
    description: DockerOptionsMessages.envDescription,
    isSupported: true,
    names: ['-e', '--env'],
  },
  {
    description: DockerOptionsMessages.entrypointDescription,
    isSupported: true,
    names: ['--entrypoint'],
  },
  {
    action: (value) => dockerParseExposeAction(value),
    description: DockerOptionsMessages.exposeDescription,
    isSupported: true,
    names: ['--expose'],
  },
  {
    action: (value) => dockerParseGpusAction(value),
    description: DockerOptionsMessages.gpusDescription,
    isRecommended: true,
    isSupported: true,
    names: ['--gpus'],
    recommendedDescription: DockerOptionsMessages.noGpuConfiguredDescription,
  },
  {
    action: (value) => dockerParseLogDriverAction(value),
    description: DockerOptionsMessages.logDriverDescription,
    isSupported: true,
    names: ['--log-driver'],
  },
  {
    action: (value) => dockerParseLogOptAction(value),
    description: DockerOptionsMessages.logOptDescription,
    isSupported: true,
    names: ['--log-opt'],
  },
  {
    action: (value) => dockerParseMemoryAction(value),
    description: DockerOptionsMessages.memoryDescription,
    isSupported: true,
    names: ['-m', '--memory'],
  },
  {
    action: (value) => dockerParseNameAction(value),
    description: DockerOptionsMessages.nameDescription,
    isSupported: true,
    names: ['--name'],
  },
  {
    action: (value) => dockerParsePortAction(value),
    description: DockerOptionsMessages.portDescription,
    isSupported: true,
    names: ['-p', '--publish'],
  },
  {
    action: (value) => dockerParseRestartAction(value),
    description: DockerOptionsMessages.restartDescription,
    isSupported: true,
    names: ['--restart'],
  },
  {
    action: (value) => dockerParseStorageOptAction(value),
    description: DockerOptionsMessages.storageOptDescription,
    isSupported: true,
    names: ['--storage-opt'],
  },
  {
    description: DockerOptionsMessages.rmDescription,
    hasNoValue: true,
    isSupported: true,
    names: ['--rm'],
  },
]

const unsupportedOptions: DockerOption[] = [
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--add-host'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['-a', '--attach'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--annotation'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--blkio-weight-device'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--blkio-weight'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cap-add'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cap-drop'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cgroup-parent'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cgroupns'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cidfile'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cpu-count'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cpu-period'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cpu-percent'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cpu-quota'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cpu-rt-period'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cpu-rt-runtime'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['-c', '--cpu-shares'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cpuset-cpus'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--cpuset-mems'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    hasNoValue: true,
    isSupported: false,
    names: ['-d', '--detach'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--detach-keys'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--device'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--device-cgroup-rule'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--device-read-bps'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--device-read-iops'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--device-write-bps'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--device-write-iops'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--disable-content-trust'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--dns'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--dns-option'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--dns-search'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--domainname'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--env-file'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--group-add'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--health-cmd'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--health-interval'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--health-retries'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--health-start-interval'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--health-start-period'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--health-timeout'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    hasNoValue: true,
    isSupported: false,
    names: ['--help'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['-h', '--hostname'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--init'],
  },
  {
    description: DockerOptionsMessages.interactiveDescription,
    hasNoValue: true,
    isSupported: false,
    isWarning: true,
    learnMore: 'https://docs.salad.com/container-engine',
    names: ['-i', '--interactive'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--io-maxbandwidth'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--io-maxiops'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--ip'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--ip6'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--ipc'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--isolation'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--kernel-memory'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['-l', '--label'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--label-file'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--link'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--link-local-ip'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--mac-address'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--memory-reservation'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--memory-swap'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--memory-swappiness'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--mount'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--network'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--network-alias'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--no-healthcheck'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--oom-kill-disable'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--oom-score-adj'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--pid'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--pids-limit'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--platform'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--privileged'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    hasNoValue: true,
    isSupported: false,
    names: ['-P', '--publish-all'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--pull'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    hasNoValue: true,
    isSupported: false,
    names: ['-q', '--quiet'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    hasNoValue: true,
    isSupported: false,
    names: ['--read-only'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--runtime'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--security-opt'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--shm-size'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--sig-proxy'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--stop-signal'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--stop-timeout'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--sysctl'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--tmpfs'],
  },
  {
    description: DockerOptionsMessages.ttyDescription,
    hasNoValue: true,
    isSupported: false,
    isWarning: true,
    learnMore: 'https://docs.salad.com/container-engine',
    names: ['-t', '--tty'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--ulimit'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['-u', '--user'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--userns'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--uts'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['-v', '--volume'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--volume-driver'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['--volumes-from'],
  },
  {
    description: DockerOptionsMessages.notSupported,
    isSupported: false,
    names: ['-w', '--workdir'],
  },
]

export const dockerOptions: DockerOption[] = [...supportedOptions, ...unsupportedOptions]
