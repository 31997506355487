export enum ExpirationDelayOptionInSeconds {
  /** The API Key will expire immediately. */
  Now = 0,
  /** The API Key will expire in 1 hour. */
  OneHour = 3600,
  /** The API Key will expire in 1 day. */
  OneDay = 86400,
  /** The API Key will expire in 1 week. */
  OneWeek = 604800,
}
