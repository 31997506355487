import { ResponseError, UpdateRecipeDeploymentProblemType } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import {
  OrganizationDataAPI,
  OrganizationOptionsAPI,
  OrganizationsAPI,
  ProjectsAPI,
  QuotasAPI,
  RecipeDeploymentsAPI,
  assertUnreachable,
} from '../apiMethods'
import {
  editRecipeDeployment,
  getRecipeDeploymentToEdit,
  setEditRecipeDeploymentRequestStatus,
  setGetRecipeDeploymentToEditRequestStatus,
  setIsRecipeInstancesQuotaExceeded,
  setRecipeDeploymentToEdit,
} from '../features/editRecipeDeployment/editRecipeDeploymentSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { quotasAddedToOrganization } from '../features/quotas/quotasSlice'
import { ramOptionsReceived } from '../features/ramOptions/ramOptionsSlice'
import { RequestStatus } from '../models'
import {
  getEditRecipeDeploymentGeneralErrorContent,
  getEditRecipeDeploymentSuccessContent,
  getRecipeDeploymentNoLongerAvailableToEditContent,
  getRecipeDeploymentToEditGeneralErrorContent,
} from '../notifications/clientToastNotificationContent/recipes'
import { getRecipeDeploymentDetailsPagePath, getRecipeDeploymentsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { getRecipeGpuPrice } from '../utils/reciprePriorityPrice'
import { navigateTo } from './navigationEpic'

export const onEditRecipeDeployment: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(editRecipeDeployment.match),
    mergeMap((action) =>
      concat(
        of(setEditRecipeDeploymentRequestStatus({ status: RequestStatus.Loading })),
        from(
          RecipeDeploymentsAPI.updateRecipeDeployment({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
            recipeDeploymentName: action.payload.recipeDeploymentName,
            updateRecipeDeployment: {
              displayName: action.payload.displayName,
              replicas: action.payload.replicaCount,
            },
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setEditRecipeDeploymentRequestStatus({ status: RequestStatus.Succeeded }),
                showToastNotification(getEditRecipeDeploymentSuccessContent(intl)),
                navigateTo({
                  path: getRecipeDeploymentDetailsPagePath(
                    action.payload.organizationName,
                    action.payload.projectName,
                    action.payload.recipeDeploymentName,
                  ),
                }),
              ),
              of(setEditRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) =>
            error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as UpdateRecipeDeploymentProblemType | null
                    if (errorType != null && errorType !== UpdateRecipeDeploymentProblemType.Null) {
                      switch (errorType) {
                        case UpdateRecipeDeploymentProblemType.UpdatedInstanceQuotaExceeded:
                          return concat(
                            of(
                              setIsRecipeInstancesQuotaExceeded({ exceeded: true }),
                              setEditRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                            ),
                            of(setEditRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                          )

                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(getEditRecipeDeploymentGeneralErrorContent(intl)),
                          setEditRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                        ),
                        of(setEditRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(getEditRecipeDeploymentGeneralErrorContent(intl)),
                    setEditRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                  ),
                  of(setEditRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                ),
          ),
        ),
      ),
    ),
  )

export const onGetRecipeDeploymentToEdit: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getRecipeDeploymentToEdit.match),
    mergeMap((action) =>
      concat(
        of(
          setGetRecipeDeploymentToEditRequestStatus({ status: RequestStatus.Loading }),
          setIsRecipeInstancesQuotaExceeded({ exceeded: false }),
        ),
        from(
          Promise.all([
            RecipeDeploymentsAPI.getRecipeDeployment({
              organizationName: action.payload.organizationName,
              projectName: action.payload.projectName,
              recipeDeploymentName: action.payload.recipeDeploymentName,
            }),
            QuotasAPI.getQuotas({
              organizationName: action.payload.organizationName,
            }),
            OrganizationOptionsAPI.listRamOptions({
              organizationName: action.payload.organizationName,
            }),
            OrganizationsAPI.getOrganization({
              organizationName: action.payload.organizationName,
            }),
            ProjectsAPI.listProjects({
              organizationName: action.payload.organizationName,
            }),
          ]),
        ).pipe(
          mergeMap(([recipeDeployment, quotasResponse, ramOptionsResponse, organizationResponse, projectsResponse]) => {
            const gpuClassIds = recipeDeployment.recipe.resources.gpuClasses
            if (gpuClassIds) {
              return from(
                OrganizationDataAPI.listGpuClasses({
                  organizationName: action.payload.organizationName,
                }),
              ).pipe(
                mergeMap((response) => {
                  const gpuClasses = response.items.filter((gpuClass) => gpuClassIds.includes(gpuClass.id))
                  const gpuClassesTotalPrice = gpuClasses.reduce(
                    (acc, gpuClass) => acc + getRecipeGpuPrice(gpuClass),
                    0,
                  )
                  return concat(
                    of(
                      setGetRecipeDeploymentToEditRequestStatus({ status: RequestStatus.Succeeded }),
                      setRecipeDeploymentToEdit({
                        gpuPrice: gpuClassesTotalPrice,
                        recipeDeployment,
                        ramOptions: ramOptionsResponse.items,
                      }),
                      ramOptionsReceived({ ramOptions: ramOptionsResponse.items }),
                      organizationAdded(organizationResponse),
                      projectsAddedToOrganization({
                        organizationName: action.payload.organizationName,
                        projects: projectsResponse.items,
                      }),
                      quotasAddedToOrganization({
                        organizationName: action.payload.organizationName,
                        quotas: quotasResponse,
                      }),
                    ),
                    of(setGetRecipeDeploymentToEditRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                  )
                }),
              )
            }
            return concat(
              of(
                setGetRecipeDeploymentToEditRequestStatus({ status: RequestStatus.Succeeded }),
                setRecipeDeploymentToEdit({
                  gpuPrice: undefined,
                  recipeDeployment,
                  ramOptions: ramOptionsResponse.items,
                }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: action.payload.organizationName,
                  projects: projectsResponse.items,
                }),
                quotasAddedToOrganization({
                  organizationName: action.payload.organizationName,
                  quotas: quotasResponse,
                }),
              ),
              of(setGetRecipeDeploymentToEditRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            )
          }),
          catchError((error) =>
            error.response.status === 404
              ? concat(
                  of(
                    showToastNotification(getRecipeDeploymentNoLongerAvailableToEditContent(intl)),
                    setGetRecipeDeploymentToEditRequestStatus({ status: RequestStatus.Failed }),
                    navigateTo({
                      path: getRecipeDeploymentsPagePath(action.payload.organizationName, action.payload.projectName),
                    }),
                  ),
                  of(setGetRecipeDeploymentToEditRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                )
              : concat(
                  of(
                    showToastNotification(getRecipeDeploymentToEditGeneralErrorContent(intl)),
                    setGetRecipeDeploymentToEditRequestStatus({ status: RequestStatus.Failed }),
                    navigateTo({
                      path: getRecipeDeploymentsPagePath(action.payload.organizationName, action.payload.projectName),
                    }),
                  ),
                  of(setGetRecipeDeploymentToEditRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                ),
          ),
        ),
      ),
    ),
  )
