import { FunctionComponent } from 'react'

interface CodeBlockProps {
  /** The value to display in the code block */
  value: string
}

export const CodeBlock: FunctionComponent<CodeBlockProps> = ({ value }) => {
  return <code className="rounded bg-neutral-30 px-2 py-1">{value}</code>
}

export default CodeBlock
