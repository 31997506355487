import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Modal } from '../../../../components/block/Modal'
import { ChangeWebhookSecretKeyModalMessages } from '../../messages'

export interface ChangeWebhookModalProps {
  /** The flag indicating that the request to change a user's webhook is pending. */
  isChangeWebhookSecretKeyPending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /** The callback executed when the user clicks the `Change webhook` button. */
  onChangeWebhookSecretKey: () => void
}

export const ChangeWebhookModal: FunctionComponent<ChangeWebhookModalProps> = ({
  isChangeWebhookSecretKeyPending,
  onCancel,
  onChangeWebhookSecretKey,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCancel} title={intl.formatMessage(ChangeWebhookSecretKeyModalMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(ChangeWebhookSecretKeyModalMessages.title)}</h1>
      <p className="mb-8">{intl.formatMessage(ChangeWebhookSecretKeyModalMessages.description)}</p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCancel} isFullWidth>
          {intl.formatMessage(ChangeWebhookSecretKeyModalMessages.cancelButtonLabel)}
        </Button>
        <Button
          variant="green-filled"
          onClick={onChangeWebhookSecretKey}
          isDisabled={isChangeWebhookSecretKeyPending}
          isLoading={isChangeWebhookSecretKeyPending}
          isFullWidth
        >
          {intl.formatMessage(ChangeWebhookSecretKeyModalMessages.changeWebhookSecretKeyButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}
