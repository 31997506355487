import { defineMessages } from 'react-intl'

export const PriorityInputMessages = defineMessages({
  batchPriorityLabel: {
    defaultMessage: 'Batch',
    id: 'RRqEch1N',
    description: 'The label for the batch priority option.',
  },
  descriptionPartOne: {
    defaultMessage:
      'Lower cost by allowing interruptions to your container group. All instances will be reallocated occasionally due to our distributed infrastructure. {learn_more}.',
    id: '/nQZZwbU',
    description: 'The first part of the description for the set priority section.',
  },
  descriptionPartTwo: {
    defaultMessage: 'Non-GPU container groups are prioritized as Batch priority.',
    id: 'CVQ3UY2B',
    description: 'The second part of the description for the set priority section.',
  },
  header: {
    defaultMessage: 'Set Priority',
    id: 'J3WmyauF',
    description: 'The header for the priority section.',
  },
  highPriorityLabel: {
    defaultMessage: 'High',
    id: 'r0nSGJzW',
    description: 'The label for the high priority option.',
  },
  label: {
    defaultMessage: 'Priority',
    id: '9NG4OxnC',
    description: 'The label for the Priority input.',
  },
  learMoreLinkText: {
    defaultMessage: 'Learn more',
    id: 'vAiFb7Hy',
    description: 'The text for the learn more link.',
  },
  lowPriorityLabel: {
    defaultMessage: 'Low',
    id: 'yT1ssXsc',
    description: 'The label for the low priority option.',
  },
  mediumPriorityLabel: {
    defaultMessage: 'Medium',
    id: 'd70rdlWz',
    description: 'The label for the medium priority option.',
  },
})
