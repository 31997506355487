import type { FunctionComponent } from 'react'
import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../../components/base'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { getAPIKeyCopiedToClipboardSucceededContent } from '../../../../notifications/clientToastNotificationContent/apiKey'
import { createToastNotification } from '../../../../notifications/createToastNotification'
import { changeAPIKeyPageRoutePath } from '../../../../routes/routePaths'
import { APIKeyMainContentMessages } from '../../messages'

interface APIKeyPageMainContentProps {
  /** The API Key. */
  apiKey: string
}

export const APIKeyPageMainContent: FunctionComponent<APIKeyPageMainContentProps> = ({ apiKey }) => {
  const [revealPassword, setRevealPassword] = useState<boolean>(false)
  const intl = useIntl()

  const handleCopyAPIKey = useCallback(
    (apiKey: string) => {
      navigator.clipboard.writeText(apiKey)
      createToastNotification(getAPIKeyCopiedToClipboardSucceededContent(intl))
    },
    [intl],
  )

  return (
    <div className="w-full max-w-2xl">
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(APIKeyMainContentMessages.title)}</h1>
      <p className="mb-8 text-base">{intl.formatMessage(APIKeyMainContentMessages.description)}</p>
      <TextField
        defaultValue={apiKey}
        key={apiKey}
        label={intl.formatMessage(APIKeyMainContentMessages.currentAPIKeyLabel)}
        onRightIconClick={() => setRevealPassword(!revealPassword)}
        readonly
        rightIconClassName={`fa-solid ${revealPassword ? 'fa-eye-slash' : 'fa-eye'}`}
        rightIconColor="text-blue-80"
        type={revealPassword ? 'text' : 'password'}
        isFullWidth
      />
      <div className="flex flex-wrap gap-2">
        <Button type="button" variant="green-filled-light" onClick={() => handleCopyAPIKey(apiKey)}>
          {intl.formatMessage(APIKeyMainContentMessages.copyAPIKeyButtonLabel)}
        </Button>
        <ButtonLink url={changeAPIKeyPageRoutePath} variant="blue-filled-light">
          {intl.formatMessage(APIKeyMainContentMessages.generateAPIKeyButtonLabel)}
        </ButtonLink>
      </div>
    </div>
  )
}
