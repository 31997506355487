import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RequestStatus, RequestType } from './models'

export type RequestStatusState = Record<RequestType, RequestStatus>

const initialState: RequestStatusState = {
  acceptTeamInvitation: 'idle',
  confirmPasswordReset: 'idle',
  confirmRegistration: 'idle',
  createContainerGroup: 'idle',
  createInferenceEndpointJob: 'idle',
  createJobQueue: 'idle',
  createOrganizationAndProject: 'idle',
  createPaymentMethodSetupIntent: 'idle',
  declineTeamInvitation: 'idle',
  deleteAccount: 'idle',
  deleteContainerGroup: 'idle',
  deleteJobQueue: 'idle',
  deleteOrganization: 'idle',
  deleteProject: 'idle',
  editContainerGroup: 'idle',
  editJobQueue: 'idle',
  editOrganization: 'idle',
  fetchTerminalToken: 'idle',
  getApiKey: 'idle',
  getBillingCreditsDashboardEmbedUrl: 'idle',
  getBillingCustomerPortal: 'idle',
  getBillingInvoiceDashboardEmbedUrl: 'idle',
  getBillingPageData: 'idle',
  getBillingUsageDashboardEmbedUrl: 'idle',
  getContainerGroupAndInstances: 'idle',
  getContainerGroupDetailsPageData: 'idle',
  getContainerGroupInstanceDetailsPageData: 'idle',
  getContainerGroupInstances: 'idle',
  getContainerGroupLogs: 'idle',
  getContainerGroupsPageData: 'idle',
  getCreateContainerGroupPageData: 'idle',
  getEditContainerGroupPageData: 'idle',
  getEditJobQueuePageData: 'idle',
  getInferenceEndpointDetailsPageData: 'idle',
  getInferenceEndpointJob: 'idle',
  getInferenceEndpointsMarketplacePageData: 'idle',
  getInviteTeamMemberPageData: 'idle',
  getJobQueueDetailsPageData: 'idle',
  getJobQueues: 'idle',
  getJobQueuesPageData: 'idle',
  getMyOrganizationMembersAndInvitations: 'idle',
  getMyOrganizationsList: 'idle',
  getOrganization: 'idle',
  getOrganizationWithProjects: 'idle',
  getPaymentMethodSetupIntent: 'idle',
  getRecipeDeploymentsPageData: 'idle',
  getRecipeDetails: 'idle',
  getRecipeMarketplacePageData: 'idle',
  getWebhookSecretKey: 'idle',
  inviteTeamMember: 'idle',
  login: 'idle',
  pollContainerGroups: 'idle',
  registerAccount: 'idle',
  removePaymentMethod: 'idle',
  resendRegistrationEmail: 'idle',
  sendResetPasswordEmail: 'idle',
  startContainerGroup: 'idle',
  stopContainerGroup: 'idle',
  updateApiKey: 'idle',
  updateWebhookSecretKey: 'idle',
}

export const requestStatusSlice = createSlice({
  name: 'requestStatus',
  initialState,
  reducers: {
    setRequestStatus(state, action: PayloadAction<{ request: RequestType; status: RequestStatus }>) {
      state[action.payload.request] = action.payload.status
    },
  },
})

export const { setRequestStatus } = requestStatusSlice.actions
