import { defineMessages } from 'react-intl'

export const WebhookSecretKeyCopiedToClipboardSucceededContentMessages = defineMessages({
  body: {
    defaultMessage: 'Your Webhook Secret Key has been copied to your clipboard',
    id: 'ezK2qLpH',
    description:
      'The description shown in a toast notification message shown when the user successfully copied the Webhook Secret Key to their clipboard.',
  },
  title: {
    defaultMessage: 'Webhook Secret Key Copied',
    id: 'ggWItNOS',
    description:
      'The toast notification title shown when the user successfully copied the Webhook Secret Key to their clipboard.',
  },
})
