import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { WebhookSecretKeyAPI } from '../apiMethods'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getWebhookSecretKey,
  setWebhookSecretKey,
  updateWebhookSecretKey,
} from '../features/webhookSecretKey/webhookSecretKeySlice'
import {
  getChangeWebhookSecretKeyFailedContent,
  getChangeWebhookSecretKeySucceededContent,
  getLoadWebhookSecretKeyFailedContent,
} from '../notifications/clientToastNotificationContent/webhookSecretKey'
import type { AppEpic } from '../store'

export const onGetWebhookSecretKey: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getWebhookSecretKey.match),
    mergeMap((action) => {
      return concat(
        of(setRequestStatus({ request: 'getWebhookSecretKey', status: 'pending' })),
        from(WebhookSecretKeyAPI.getWebhookSecretKey({ organizationName: action.payload.organizationName })).pipe(
          mergeMap((response) => {
            return concat(
              of(
                setWebhookSecretKey({
                  webhookSecretKey: response.secretKey,
                }),
                setRequestStatus({ request: 'getWebhookSecretKey', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getWebhookSecretKey', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() => {
            return concat(
              of(
                showToastNotification(getLoadWebhookSecretKeyFailedContent(intl)),
                setRequestStatus({ request: 'getWebhookSecretKey', status: 'failed' }),
              ),
              of(setRequestStatus({ request: 'getWebhookSecretKey', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      )
    }),
  )

export const onUpdateWebhookSecretKey: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(updateWebhookSecretKey.match),
    mergeMap((action) => {
      return concat(
        of(setRequestStatus({ request: 'updateWebhookSecretKey', status: 'pending' })),
        from(WebhookSecretKeyAPI.updateWebhookSecretKey({ organizationName: action.payload.organizationName })).pipe(
          mergeMap((response) => {
            return concat(
              of(
                setWebhookSecretKey({
                  webhookSecretKey: response.secretKey,
                }),
                setRequestStatus({ request: 'updateWebhookSecretKey', status: 'succeeded' }),
                showToastNotification(getChangeWebhookSecretKeySucceededContent(intl)),
              ),
              of(setRequestStatus({ request: 'updateWebhookSecretKey', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() => {
            return concat(
              of(
                setRequestStatus({ request: 'updateWebhookSecretKey', status: 'failed' }),
                showToastNotification(getChangeWebhookSecretKeyFailedContent(intl)),
              ),
              of(setRequestStatus({ request: 'updateWebhookSecretKey', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      )
    }),
  )
