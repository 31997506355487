export enum CreateContainerGroupFormSectionIdAttributes {
  COMMAND = 'command',
  CONTAINER_GATEWAY = 'container_gateway',
  CONTAINER_GROUP_NAME = 'container_group_name',
  DISK_SPACE = 'disk_space',
  ENVIRONMENT_VARIABLES = 'environment_variables',
  EXTERNAL_LOGGING_SERVICE = 'external_logging_service',
  GPU = 'gpu',
  IMAGE_SOURCE = 'image_source',
  JOB_QUEUE = 'job_queue',
  LIVENESS_PROBE = 'liveness_probe',
  MEMORY = 'memory',
  PRIORITY = 'priority',
  READINESS_PROBE = 'readiness_probe',
  REPLICA_COUNT = 'replica_count',
  STARTUP_PROBE = 'startup_probe',
  VCPU = 'v_cpu',
}
