import { ContainerGroupPriority } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { ClientGpuClass, ClientGpuOptions } from '../features/gpuClasses/models'
import CPUCoreOptions from './CPUCoreOptions.json'

export const minimumFractionDigits = 3

export const getCPUCoreOptions = () => {
  return CPUCoreOptions.options
}

export const getFirstCPUCoreOption = () => {
  return CPUCoreOptions.options[0]
}

export const getCPUCoreCostPerHour = (cores: number) => {
  return CPUCoreOptions.options.find((option) => option.cores === cores)?.costPerHour || 0
}

export const getGpuClassCostPerHour = (gpuClassNameOrId?: string | null, gpuClassList?: ClientGpuOptions) => {
  return (
    gpuClassList?.find((gpuClass) => gpuClass.name === gpuClassNameOrId || gpuClass.id === gpuClassNameOrId)?.price || 0
  )
}

/**
 * Given a list of GPU options, will return the price for the GPU class based on the selected Container Group Priority.
 * If the priority is not provided, it will default to the High priority price.
 *
 * @param gpuOptions The list of gpuOptions.
 * @param selectedGpuId The unique identifier of the selected GPU Class.
 * @param priority The selected Container Group Priority.
 * @returns The price for the GPU class based on the selected Container Group Priority.
 */
export const getGpuClassPriorityPrice = (
  gpuOptions: ClientGpuOptions,
  selectedGpuId: string,
  priority: ContainerGroupPriority = ContainerGroupPriority.High,
): number => {
  const gpuClass = gpuOptions?.find((gpuClass) => gpuClass.id === selectedGpuId)
  if (!gpuClass) {
    return 0
  }

  return getGpuClassPriceBasedOnPriority(gpuClass, priority)
}

/**
 * Returns the price for the GPU class based on the selected Container Group Priority. If the priority is not provided,
 * it will default to the High priority price.
 *
 * @param gpuClass The GPU class.
 * @param priority The selected Container Group Priority.
 * @returns The price for the GPU class based on the selected Container Group Priority.
 */
export const getGpuClassPriceBasedOnPriority = (
  gpuClass: ClientGpuClass,
  priority: ContainerGroupPriority = ContainerGroupPriority.High,
): number => {
  const price = gpuClass.prices.find((price) => price.priority === priority)?.price || 0
  return price
}
