import type { SyntheticEvent } from 'react'

const env = process.env['NODE_ENV']
const isDevelopmentEnv = env === 'development'
export const contactSupportViaHelpScoutAsks = (
  setIsHelpScoutBeaconFailedToLoadModalOpen?: (isOpen: boolean) => void,
  trackHelpScoutFailedToOpenMixpanelEvent?: () => void,
  event?: SyntheticEvent,
) => {
  event?.preventDefault()
  const beacon = window.Beacon
  if (beacon && beacon('info') !== undefined) {
    if (isDevelopmentEnv) {
      beacon('navigate', '/ask/')
    }
    beacon('open')
  } else {
    trackHelpScoutFailedToOpenMixpanelEvent && trackHelpScoutFailedToOpenMixpanelEvent()
    setIsHelpScoutBeaconFailedToLoadModalOpen && setIsHelpScoutBeaconFailedToLoadModalOpen(true)
  }
}
