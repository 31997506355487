import { defineMessages } from 'react-intl'

export const ChangeAPIKeyMainContentMessages = defineMessages({
  backToAPIKeyPageLinkText: {
    defaultMessage: 'Return to API Key page',
    id: 'kJkxSvQp',
    description: 'The text for the Return to API Key page link.',
  },
  changeAPIKeyButtonLabel: {
    defaultMessage: 'Change API Key',
    id: 'VWtK/4dH',
    description: 'The label for the Change API Key button.',
  },
  currentAPIKeyExpirationDelayLabel: {
    defaultMessage: 'Current API Key Expiration Date',
    id: 'LP0pGLS4',
    description: 'The label for the current API Key expiration delay select field.',
  },
  currentAPIKeyExpirationDelayNowOptionLabel: {
    defaultMessage: 'Now',
    id: '2/+8zABH',
    description: 'The label for the "Now" option in the current API Key expiration delay select field.',
  },
  currentAPIKeyExpirationDelayOneDayOptionLabel: {
    defaultMessage: '1 Day',
    id: 'ylLD+FtG',
    description: 'The label for the "1 Day" option in the current API Key expiration delay select field.',
  },
  currentAPIKeyExpirationDelayOneHourOptionLabel: {
    defaultMessage: '1 Hour',
    id: 'u1cE4I+R',
    description: 'The label for the "1 Hour" option in the current API Key expiration delay select field.',
  },
  currentAPIKeyExpirationDelayOneWeekOptionLabel: {
    defaultMessage: '1 Week',
    id: 'wFLl1Wu/',
    description: 'The label for the "1 Week" option in the current API Key expiration delay select field.',
  },
  currentAPIKeyLabel: {
    defaultMessage: 'Current API Key',
    id: 'o4C7E0qB',
    description: 'The label for the current API Key readonly text field.',
  },
  description: {
    defaultMessage:
      "Changing the API Key will invalidate the current API Key and generate a new one. You'll need to update the API Key in your application to continue using the API.",
    id: 'bMqGls9e',
    description: 'The description for the Change API Key page.',
  },
  title: {
    defaultMessage: 'Change API Key',
    id: '53nLrtl0',
    description: 'The title for the Change API Key page.',
  },
})
