import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'

export type TagColor = 'gray' | 'white' | 'green' | 'darkGreen' | 'blue' | 'yellow' | 'red' | 'black'

export interface TagProps {
  /** The children. */
  children: ReactNode
  /** The color style. */
  color?: TagColor
  /** The flag indicating the `cursor-help` style should be applied. */
  hasHelpCursor?: boolean
  /** Tag's click handler. */
  onClick?: () => void
}

const ClickableTag: FunctionComponent<
  Required<Pick<TagProps, 'children' | 'color' | 'onClick'>> & { className: string }
> = ({ children, className, onClick }) => (
  <button onClick={onClick} className={className}>
    {children}
  </button>
)

const InfoTag: FunctionComponent<Exclude<TagProps, 'onClick' | 'hasHelpCursor'> & { className: string }> = ({
  children,
  className,
}) => <div className={className}>{children}</div>

export const Tag: FunctionComponent<TagProps> = ({ children, color = 'green', hasHelpCursor, onClick }) => {
  const className = classNames('w-fit rounded-full weight-md px-2 py-1 font-sans text-xs font-medium', {
    'bg-green-10 text-green-100': color === 'green',
    'bg-green-90 text-neutral-10': color === 'darkGreen',
    'bg-neutral-20 text-neutral-100': color === 'gray',
    'bg-neutral-10 text-neutral-100 border-[1px] border-neutral-30': color === 'white',
    'bg-blue-10 text-blue-100': color === 'blue',
    'bg-yellow-10 text-yellow-100': color === 'yellow',
    'bg-red-10 text-red-90': color === 'red',
    'bg-neutral-100 text-neutral-10': color === 'black',
    'cursor-help': hasHelpCursor,
  })

  return onClick !== undefined ? (
    <ClickableTag className={className} color={color ?? 'green'} onClick={onClick}>
      {children}
    </ClickableTag>
  ) : (
    <InfoTag className={className} color={color}>
      {children}
    </InfoTag>
  )
}
