import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from '../../../../components/base'
import { Button } from '../../../../components/Button'
import { Select } from '../../../../components/forms/Select'
import { TextField } from '../../../../components/TextField'
import { ExpirationDelayOptionInSeconds } from '../../../../features/apiKey/models'
import { useFixedForm } from '../../../../hooks'
import { apiKeyPageRoutePath } from '../../../../routes/routePaths'
import { ChangeAPIKeyMainContentMessages } from '../../messages'
import { type ChangeAPIKeyFormValues } from '../../models'
import { useChangeAPIKeyFormValidation } from './useChangeAPIKeyFormValidation'

interface ChangeAPIKeyPageMainContentProps {
  /** The current API Key. */
  currentAPIKey: string
  /** The flag indicating that the request to change the API Key is pending. */
  isChangeAPIKeyRequestPending: boolean
  /** The callback executed when the user clicks on the Change API Key button. */
  onChangeAPIKey: (currentAPIKeyExpirationDelay?: ExpirationDelayOptionInSeconds) => void
}

export const ChangeAPIKeyPageMainContent: FunctionComponent<ChangeAPIKeyPageMainContentProps> = ({
  currentAPIKey,
  isChangeAPIKeyRequestPending,
  onChangeAPIKey,
}) => {
  const intl = useIntl()
  const [revealCurrentAPIKey, setRevealCurrentAPIKey] = useState<boolean>(false)
  const changeAPIKeyFormValidationScheme = useChangeAPIKeyFormValidation()

  const { control, handleSubmit } = useFixedForm<ChangeAPIKeyFormValues>({
    defaultValues: { currentAPIKeyExpirationDelay: ExpirationDelayOptionInSeconds.Now },
    resolver: yupResolver(changeAPIKeyFormValidationScheme),
    onSubmit: ({ currentAPIKeyExpirationDelay }) => onChangeAPIKey(currentAPIKeyExpirationDelay),
  })

  return (
    <div className="w-full max-w-2xl">
      <div className="mb-4">
        <Link url={apiKeyPageRoutePath}>
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(ChangeAPIKeyMainContentMessages.backToAPIKeyPageLinkText)}
        </Link>
      </div>

      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(ChangeAPIKeyMainContentMessages.title)}</h1>
      <p className="mb-8 text-base">{intl.formatMessage(ChangeAPIKeyMainContentMessages.description)}</p>
      <TextField
        defaultValue={currentAPIKey}
        key={currentAPIKey}
        label={intl.formatMessage(ChangeAPIKeyMainContentMessages.currentAPIKeyLabel)}
        onRightIconClick={() => setRevealCurrentAPIKey(!revealCurrentAPIKey)}
        readonly
        rightIconClassName={`fa-solid ${revealCurrentAPIKey ? 'fa-eye-slash' : 'fa-eye'}`}
        rightIconColor="text-blue-80"
        type={revealCurrentAPIKey ? 'text' : 'password'}
        isFullWidth
      />

      <form onSubmit={handleSubmit}>
        <div className="mb-12">
          <Controller
            name="currentAPIKeyExpirationDelay"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                {...fieldState}
                defaultSelectedValue={field.value}
                isFullWidth
                isPlaceholderOptionDisabled
                labelText={intl.formatMessage(ChangeAPIKeyMainContentMessages.currentAPIKeyExpirationDelayLabel)}
                onChange={(value) => {
                  field.onChange(value)
                }}
                options={[
                  {
                    label: intl.formatMessage(
                      ChangeAPIKeyMainContentMessages.currentAPIKeyExpirationDelayNowOptionLabel,
                    ),
                    value: ExpirationDelayOptionInSeconds.Now,
                  },
                  {
                    label: intl.formatMessage(
                      ChangeAPIKeyMainContentMessages.currentAPIKeyExpirationDelayOneHourOptionLabel,
                    ),
                    value: ExpirationDelayOptionInSeconds.OneHour,
                  },
                  {
                    label: intl.formatMessage(
                      ChangeAPIKeyMainContentMessages.currentAPIKeyExpirationDelayOneDayOptionLabel,
                    ),
                    value: ExpirationDelayOptionInSeconds.OneDay,
                  },
                  {
                    label: intl.formatMessage(
                      ChangeAPIKeyMainContentMessages.currentAPIKeyExpirationDelayOneWeekOptionLabel,
                    ),
                    value: ExpirationDelayOptionInSeconds.OneWeek,
                  },
                ]}
              />
            )}
          />
        </div>

        <Button
          type="submit"
          isLoading={isChangeAPIKeyRequestPending}
          isDisabled={isChangeAPIKeyRequestPending}
          isFullWidth
        >
          {intl.formatMessage(ChangeAPIKeyMainContentMessages.changeAPIKeyButtonLabel)}
        </Button>
      </form>
    </div>
  )
}
