import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { getWebhookSecretKeyCopiedToClipboardSucceededContent } from '../../../../notifications/clientToastNotificationContent/webhookSecretKey'
import { createToastNotification } from '../../../../notifications/createToastNotification'
import { WebhookSecretKeyMainContentMessages } from '../../messages'
import { ChangeWebhookModal } from '../ChangeWebhookSecretKeyModal'

interface WebhookSecretKeyMainContentProps {
  /** The flag indicating that the request to change the Webhook Secret key is pending. */
  isChangeWebhookSecretKeyRequestPending: boolean
  /** The flag indicating that the request to change the Webhook Secret key was successful. */
  isChangeWebhookSecretKeyRequestSuccessful?: boolean
  /** The callback executed when the user clicks on the Change Webhook Secret Key button. */
  onChangeWebhookSecretKey: () => void
  /** The Webhook Secret Key. */
  webhookSecretKey: string
}

export const WebhookSecretKeyMainContent: FunctionComponent<WebhookSecretKeyMainContentProps> = ({
  isChangeWebhookSecretKeyRequestPending,
  isChangeWebhookSecretKeyRequestSuccessful,
  onChangeWebhookSecretKey,
  webhookSecretKey,
}) => {
  const [revealPassword, setRevealPassword] = useState<boolean>(false)
  const [isChangeWebhookSecretKeyModalOpen, setIsChangeWebhookSecretKeyModalOpen] = useState<boolean>(false)
  const intl = useIntl()

  const handleCopyWebhookSecretKey = useCallback(
    (webhookSecretKey: string) => {
      navigator.clipboard.writeText(webhookSecretKey)
      createToastNotification(getWebhookSecretKeyCopiedToClipboardSucceededContent(intl))
    },
    [intl],
  )

  useEffect(() => {
    setIsChangeWebhookSecretKeyModalOpen(false)
  }, [isChangeWebhookSecretKeyRequestSuccessful])

  return (
    <div className="w-full max-w-2xl">
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(WebhookSecretKeyMainContentMessages.title)}</h1>
      <TextField
        defaultValue={webhookSecretKey}
        key={webhookSecretKey}
        label={intl.formatMessage(WebhookSecretKeyMainContentMessages.currentWebhookSecretKeyLabel)}
        onRightIconClick={() => setRevealPassword(!revealPassword)}
        readonly
        rightIconClassName={`fa-solid ${revealPassword ? 'fa-eye-slash' : 'fa-eye'}`}
        rightIconColor="text-blue-80"
        type={revealPassword ? 'text' : 'password'}
        isFullWidth
      />
      <div className="flex flex-wrap gap-2">
        <Button type="button" variant="green-filled-light" onClick={() => handleCopyWebhookSecretKey(webhookSecretKey)}>
          {intl.formatMessage(WebhookSecretKeyMainContentMessages.copyWebhookSecretKeyButtonLabel)}
        </Button>
        <Button
          onClick={() => setIsChangeWebhookSecretKeyModalOpen(true)}
          isLoading={isChangeWebhookSecretKeyRequestPending}
          isDisabled={isChangeWebhookSecretKeyRequestPending}
          type="button"
          variant="blue-filled-light"
        >
          {intl.formatMessage(WebhookSecretKeyMainContentMessages.generateWebhookSecretKeyButtonLabel)}
        </Button>
      </div>

      {isChangeWebhookSecretKeyModalOpen && (
        <ChangeWebhookModal
          isChangeWebhookSecretKeyPending={isChangeWebhookSecretKeyRequestPending}
          onCancel={() => setIsChangeWebhookSecretKeyModalOpen(false)}
          onChangeWebhookSecretKey={onChangeWebhookSecretKey}
        />
      )}
    </div>
  )
}
