import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { DrawerContentWrapper } from './DrawerContentWrapper'
import { DrawerMessages } from './messages'

interface DrawerProps {
  /** A value indicating side from which the drawer will appear. */
  anchor: 'left' | 'right'
  /** The drawer content. */
  children: ReactNode
  /** A flag indicating the drawer is shown. */
  isOpen?: boolean
  /**
   * The flag indicating if the drawer has showcase styles applied. This will make the drawer take up more width and
   * have a grey background.
   */
  isShowcase?: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer?: () => void
}

export const Drawer: FunctionComponent<DrawerProps> = ({
  anchor = 'left',
  children,
  isOpen = false,
  isShowcase,
  onCloseLeftDrawer,
}) => {
  const intl = useIntl()

  return (
    <aside
      className={classNames('max-sm:fixed max-sm:z-50 max-sm:w-screen sm:relative sm:shrink-0', {
        'sm:w-80': !isShowcase,
        'md:w-full md:max-w-lg': isShowcase,
      })}
      aria-label={
        anchor === 'left'
          ? intl.formatMessage(DrawerMessages.asideLeftAriaLabel)
          : intl.formatMessage(DrawerMessages.asideRightAriaLabel)
      }
    >
      <div
        className={classNames(
          'fixed h-full max-sm:transform max-sm:bg-neutral-100 max-sm:bg-opacity-50 sm:border-neutral-20 sm:bg-neutral-10 sm:shadow-sm',
          {
            'sm:w-screen sm:max-w-xs': !isShowcase,
            'md:w-screen md:max-w-lg': isShowcase,
            'max-sm:hidden': !isOpen && !isShowcase,
            'max-md:hidden': !isOpen && isShowcase,
            'max-sm:w-screen max-sm:opacity-100 max-sm:transition-opacity max-sm:duration-500 max-sm:ease-in-out':
              isOpen,
            'sm:left-0 sm:border-r': anchor === 'left',
            'sm:right-0 sm:border-l': anchor === 'right',
          },
        )}
      >
        <DrawerContentWrapper
          classes={classNames('max-sm:ease-in-out  max-sm:max-w-xs', {
            'bg-neutral-20': isShowcase,
            'max-sm:left-0 max-sm:border-r': anchor === 'left',
            'max-sm:right-0 max-sm:border-l': anchor === 'right',
            'max-sm:-translate-x-0 max-sm:delay-400  max-sm:transform  max-sm:transition-all  max-sm:duration-500':
              isOpen && anchor === 'left',
            'max-sm:-translate-x-full': !isOpen && anchor === 'left',
            'max-sm:translate-x-0 max-sm:delay-400  max-sm:transform  max-sm:transition-all  max-sm:duration-500':
              isOpen && anchor === 'right',
            'max-sm:translate-x-full': !isOpen && anchor === 'right',
          })}
        >
          {children}
        </DrawerContentWrapper>
        {onCloseLeftDrawer && (
          <div onClick={onCloseLeftDrawer} className={classNames('h-full w-full', { 'max-sm:hidden': !isOpen })} />
        )}
      </div>
    </aside>
  )
}
