import { ContainerGroupPriority, type GpuClassesList } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { ClientGpuOptions } from './models'

/**
 * Takes the response body from the OrganizationDataAPI.listGpuClasses API call and converts the price properties from a
 * string type to a number type. This also includes a soon to be deprecated "price" property for backwards
 * compatibility.
 *
 * @param organizationName The organization name
 * @param gpuClassesResponse The response body that comes from the OrganizationDataAPI.listGpuClasses API call
 */
export const configureGpuClassesResponse = (
  organizationName: string,
  gpuClassesResponse: GpuClassesList | undefined,
): { organizationName: string; gpuClasses: ClientGpuOptions } => {
  return {
    organizationName,
    gpuClasses: gpuClassesResponse
      ? gpuClassesResponse.items.map((gpuClass) => {
          // @ts-ignore - "price" property will still exist until API is updated.
          const deprecatedPrice = gpuClass['price']
          const price = deprecatedPrice ? parseFloat(deprecatedPrice) : undefined

          // if prices is undefined, return a mock prices array that just uses the price property
          if (!gpuClass.prices) {
            return {
              id: gpuClass.id,
              name: gpuClass.name,
              price,
              prices: price
                ? [
                    { price, priority: ContainerGroupPriority.High },
                    { price, priority: ContainerGroupPriority.Medium },
                    { price, priority: ContainerGroupPriority.Low },
                    { price, priority: ContainerGroupPriority.Batch },
                  ]
                : [],
              isHighDemand: gpuClass.isHighDemand,
            }
          }

          let highPriorityPrice
          const pricesCopy = [...gpuClass.prices]
          const updatePrices = pricesCopy.map((price) => {
            if (price.priority === ContainerGroupPriority.High) {
              highPriorityPrice = parseFloat(price.price)
            }
            return {
              ...price,
              price: parseFloat(price.price),
            }
          })
          return {
            id: gpuClass.id,
            name: gpuClass.name,
            price: highPriorityPrice,
            prices: updatePrices,
            isHighDemand: gpuClass.isHighDemand,
          }
        })
      : undefined,
  }
}
