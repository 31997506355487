import type { Queue } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormResetField, UseFormTrigger } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { Button } from '../../../../components/Button'
import { ContainerConnectionsMessages } from '../../messages'
import { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { ContainerGatewayFields } from '../ContainerGatewayFields'
import { JobQueueFields } from '../JobQueueFields'
import { OutboundRequests } from '../OutboundRequests'

export interface ContainerConnectionsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The flag indicating that the request to create a container group is pending. */
  isSubmitPending: boolean
  /** The function to handle navigation to the previous page */
  onBackButton: () => void
  /** The function to handle navigation to the next page */
  onNextStep: (fieldsToValidate: string[]) => void
  /** The available job queues to select from for a container group. */
  queues: Queue[]
  /** The react hook form method that provides the ability to reset a specified field value. */
  resetField: UseFormResetField<FieldValues>
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const ContainerConnections: FunctionComponent<ContainerConnectionsProps> = ({
  control,
  isSubmitPending,
  onBackButton,
  onNextStep,
  queues,
  resetField,
  trigger,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isContainerGatewaySidePanelOpen, setIsContainerGatewaySidePanelOpen] = useState<boolean>(false)

  const connectionFields = [
    CreateContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY,
    CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE,
  ]

  return (
    <div className="relative h-screen w-full pb-44">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="mb-36 w-full max-w-2xl">
          <div className="mb-4">
            <button className="text-blue-90 underline" onClick={() => navigate(-1)}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(ContainerConnectionsMessages.backLinkText)}
            </button>
          </div>
          <h1 className="mb-3 text-3xl font-bold">
            {intl.formatMessage(ContainerConnectionsMessages.containerConnectionsTitle)}
          </h1>
          <h3 className="mb-10 text-lg">
            {intl.formatMessage(ContainerConnectionsMessages.containerConnectionsSubtitle)}
          </h3>

          <OutboundRequests />

          <ContainerGatewayFields
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY}
            isContainerGatewaySidePanelOpen={isContainerGatewaySidePanelOpen}
            setIsContainerGatewaySidePanelOpen={setIsContainerGatewaySidePanelOpen}
            trigger={trigger}
          />
          <JobQueueFields
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE}
            queues={queues}
            resetField={resetField}
            trigger={trigger}
          />
        </div>
      </div>

      <div className="absolute bottom-16 left-0 mt-10 flex w-full flex-col justify-items-end bg-neutral-10 px-6 py-3 align-middle shadow">
        <div className="flex w-full justify-end gap-2">
          <Button
            isDisabled={isSubmitPending}
            isLoading={isSubmitPending}
            onClick={onBackButton}
            type="button"
            variant="green-outlined"
          >
            {intl.formatMessage(ContainerConnectionsMessages.previousButtonLabel)}
          </Button>
          <Button
            isDisabled={isSubmitPending}
            isLoading={isSubmitPending}
            onClick={() => onNextStep(connectionFields)}
            type="button"
            variant="green-filled"
          >
            {intl.formatMessage(ContainerConnectionsMessages.nextStepButtonLabel)}
          </Button>
        </div>
      </div>
    </div>
  )
}
