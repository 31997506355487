import { useMemo } from 'react'
import type { ObjectSchema } from 'yup'
import { number, object, string } from 'yup'
import type { CreateRecipeDeploymentFormValues } from './CreateRecipeDeploymentMainContent'

export const useCreateRecipeFormValidation = (maxReplicas: number): ObjectSchema<CreateRecipeDeploymentFormValues> => {
  const validationScheme = useMemo(
    () =>
      object({
        recipeDeploymentName: string()
          .required()
          .matches(/^[a-z][a-z0-9-]{0,61}[a-z0-9]$/),
        replicaCount: number().required().min(0).max(maxReplicas),
      }),
    [maxReplicas],
  )
  return validationScheme
}
