import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { APIKeyPageMainContent } from './components/APIKeyPageMainContent'

export interface APIKeyPageProps {
  /** The API Key. */
  apiKey: string
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
}

export const APIKeyPage: FunctionComponent<APIKeyPageProps> = ({ apiKey, isLeftColumnOpen, onCloseLeftDrawer }) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar />}
      MainColumn={<APIKeyPageMainContent apiKey={apiKey} />}
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
