import classNames from 'classnames'
import type { FunctionComponent } from 'react'

export interface InputLabelProps {
  /** The flag indicating that error styles should be applied. */
  hasError?: boolean
  /** The Input label. */
  label: string
}

export const InputLabel: FunctionComponent<InputLabelProps> = ({ hasError, label }) => {
  return (
    <label
      className={classNames('mb-1 inline-block text-base font-bold', {
        'text-red-70': hasError,
      })}
      htmlFor={label}
    >
      {label}
    </label>
  )
}
