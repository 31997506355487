import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { ButtonLink, Link } from '../../../../components/base'
import { EstimatedCost } from '../../../../components/recipes/EstimatedCost'
import { RecipeCard } from '../../../../components/recipes/RecipeCard/RecipeCard'
import { useFixedForm } from '../../../../hooks'
import { ReplicaCountInput } from '../../../CreateContainerGroup/components/ReplicaCountInput'
import { RecipeInstancesQuotaExceededModal } from '../../../EditRecipeDeployment/components/RecipeInstancesQuotaExceededModal'
import { CreateRecipeDeploymentMainContentMessages } from '../../messages'
import { BillingInformationMissingModal } from '../BillingInformationMissingModal'
import { RecipeQuotaExceededModal } from '../RecipeQuotaExceededModal'
import { useCreateRecipeFormValidation } from './useCreateRecipeFormValidation'

export interface CreateRecipeDeploymentFormValues {
  /** The recipe deployment name. */
  recipeDeploymentName: string
  /** The replica count for the recipe */
  replicaCount: number
}

export interface CreateRecipeDeploymentMainContentProps {
  /** The path back to the recipe marketplace. */
  changeRecipePath: string
  /** Whether or not billing information is missing. */
  isBillingInformationRequired: boolean
  /** The flag indicating that the request to create the project is pending. */
  isCreatingRecipeDeploymentPending: boolean
  /** The flag that indicating if the recipe instances quota exceeded. */
  isRecipeInstancesQuotaExceeded: boolean
  /** Whether or not the recipe quota has exceeded. */
  isRecipeQuotaExceeded: boolean
  /** The link to the typeform for requesting an increase in the recipe deployment instance quota limit. */
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas: string
  /** The link to the typeform for requesting an increase in the recipe deployment max quota limit. */
  linkToRequestIncreaseForRecipeDeploymentQuotas: string
  /** The maximum recipe count allowed (quota) */
  maxRecipeDeployments: number
  /** The max replica count. */
  maxReplicaCount: number
  /** A callback that when executed creates recipe deployment. */
  onCreateRecipeDeployment: (recipeDeploymentName: string, replicaCount: number) => void
  /** The callback executed when the user needs to enter their billing information. */
  onEnterBillingInformation: () => void
  /** A callback executed each time the replica count is updated. */
  onUpdateReplicaCount: (pendingReplicaCount: number) => void
  /** The pending recipe deployment replica count. */
  pendingReplicaCount: number
  /** The recipe cost per hour. */
  recipeCostPerHour: number
  /** The path for the recipe details. */
  recipeDetailsPath: string
  /** The selected recipe name. */
  recipeName: string
}

export const CreateRecipeDeploymentMainContent: FunctionComponent<CreateRecipeDeploymentMainContentProps> = ({
  changeRecipePath,
  isBillingInformationRequired,
  isCreatingRecipeDeploymentPending,
  isRecipeInstancesQuotaExceeded,
  isRecipeQuotaExceeded,
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas,
  linkToRequestIncreaseForRecipeDeploymentQuotas,
  maxRecipeDeployments,
  maxReplicaCount,
  onCreateRecipeDeployment,
  onEnterBillingInformation,
  onUpdateReplicaCount,
  pendingReplicaCount,
  recipeCostPerHour,
  recipeDetailsPath,
  recipeName,
}) => {
  const intl = useIntl()

  const [isBillingInformationRequiredModalShowing, setBillingInformationRequiredModalOpen] = useState(false)
  const [isRecipeQuotaExceedModalShowing, setRecipeQuotaExceededModalOpen] = useState(false)
  const [isRecipeInstancesQuotaExceedModalShowing, setRecipeInstancesQuotaExceededModalOpen] = useState(false)
  const [modalToOpenOnSubmit, setModalToOpenOnSubmit] = useState<
    'billingInformationRequiredModal' | 'recipeQuotaExceededModal' | 'recipeInstancesQuotaExceededModal' | undefined
  >(undefined)
  const availableReplicaCount = maxReplicaCount ?? 0
  const createProjectValidationScheme = useCreateRecipeFormValidation(availableReplicaCount)

  const handleShowModalOnSubmit = () => {
    if (modalToOpenOnSubmit === 'billingInformationRequiredModal') {
      setBillingInformationRequiredModalOpen(true)
    }

    if (modalToOpenOnSubmit === 'recipeQuotaExceededModal') {
      setRecipeQuotaExceededModalOpen(true)
    }

    if (modalToOpenOnSubmit === 'recipeInstancesQuotaExceededModal') {
      setRecipeInstancesQuotaExceededModalOpen(true)
    }
  }

  const { control, handleSubmit } = useFixedForm<CreateRecipeDeploymentFormValues>({
    resolver: yupResolver(createProjectValidationScheme),
    onSubmit: ({ recipeDeploymentName, replicaCount }) => {
      onCreateRecipeDeployment(recipeDeploymentName, replicaCount)
    },
  })

  useEffect(() => {
    if (isRecipeQuotaExceeded) {
      if (isBillingInformationRequired && maxRecipeDeployments === 0) {
        setModalToOpenOnSubmit('billingInformationRequiredModal')
        setBillingInformationRequiredModalOpen(true)
      } else {
        setModalToOpenOnSubmit('recipeQuotaExceededModal')
        setRecipeQuotaExceededModalOpen(true)
      }
    } else if (isRecipeInstancesQuotaExceeded) {
      if (isBillingInformationRequired && maxReplicaCount === 0) {
        setModalToOpenOnSubmit('billingInformationRequiredModal')
        setBillingInformationRequiredModalOpen(true)
      } else {
        setModalToOpenOnSubmit('recipeInstancesQuotaExceededModal')
        setRecipeInstancesQuotaExceededModalOpen(true)
      }
    }
  }, [
    isBillingInformationRequired,
    isRecipeInstancesQuotaExceeded,
    isRecipeQuotaExceeded,
    maxRecipeDeployments,
    maxReplicaCount,
  ])

  return (
    <div className="w-full max-w-md">
      <div className="mb-4">
        <Link url={recipeDetailsPath}>
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(CreateRecipeDeploymentMainContentMessages.backButtonLabel, {
            recipe_name: recipeName,
          })}
        </Link>
      </div>
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(CreateRecipeDeploymentMainContentMessages.title)}</h1>
      <p className="mb-10 text-base">
        {intl.formatMessage(CreateRecipeDeploymentMainContentMessages.description, {
          permanent_name: (
            <span className="font-bold">
              {intl.formatMessage(CreateRecipeDeploymentMainContentMessages.permanentName)}
            </span>
          ),
        })}
      </p>
      <form onSubmit={handleSubmit}>
        <Controller
          name="recipeDeploymentName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              helperText={intl.formatMessage(CreateRecipeDeploymentMainContentMessages.deploymentNameInputHelperText)}
              label={intl.formatMessage(CreateRecipeDeploymentMainContentMessages.deploymentNameInputLabel)}
              isFullWidth
              placeholder={intl.formatMessage(
                CreateRecipeDeploymentMainContentMessages.deploymentNameInputPlaceholderText,
              )}
            />
          )}
        />
        <ReplicaCountInput
          control={control}
          max={maxReplicaCount}
          onChange={onUpdateReplicaCount}
          requestIncreaseLimitLink={linkToRequestIncreaseForRecipeDeploymentInstancesQuotas}
        />
        <div className="mb-3 flex justify-between">
          <p>{intl.formatMessage(CreateRecipeDeploymentMainContentMessages.selectedRecipeLabel)}</p>
          <ButtonLink variant="blue-text" url={changeRecipePath}>
            {intl.formatMessage(CreateRecipeDeploymentMainContentMessages.changeRecipeLabel)}
          </ButtonLink>
        </div>
        <div className="mb-10">
          <RecipeCard recipeName={recipeName} />
        </div>
        <div className="my-10 md:hidden">
          <EstimatedCost replicaCount={pendingReplicaCount} costPerHour={recipeCostPerHour} />
        </div>
        {modalToOpenOnSubmit === undefined ? (
          <Button
            type="submit"
            isLoading={isCreatingRecipeDeploymentPending}
            isDisabled={isCreatingRecipeDeploymentPending}
            isFullWidth
          >
            {intl.formatMessage(CreateRecipeDeploymentMainContentMessages.deployButtonLabel)}
          </Button>
        ) : (
          <Button type="button" onClick={handleShowModalOnSubmit} isFullWidth>
            {intl.formatMessage(CreateRecipeDeploymentMainContentMessages.deployButtonLabel)}
          </Button>
        )}
        {isBillingInformationRequiredModalShowing && (
          <BillingInformationMissingModal
            onCancel={() => setBillingInformationRequiredModalOpen(false)}
            onEnterBillingInformation={onEnterBillingInformation}
          />
        )}
        {isRecipeQuotaExceedModalShowing && (
          <RecipeQuotaExceededModal
            linkToRequestIncreaseForRecipeDeploymentQuotas={linkToRequestIncreaseForRecipeDeploymentQuotas}
            onCancel={() => setRecipeQuotaExceededModalOpen(false)}
          />
        )}
        {isRecipeInstancesQuotaExceedModalShowing && (
          <RecipeInstancesQuotaExceededModal
            linkToRequestIncreaseForRecipeDeploymentInstancesQuotas={
              linkToRequestIncreaseForRecipeDeploymentInstancesQuotas
            }
            onCancel={() => setRecipeInstancesQuotaExceededModalOpen(false)}
          />
        )}
      </form>
    </div>
  )
}
