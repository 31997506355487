import { RamOption, StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import { Dict } from 'mixpanel-browser'
import { FunctionComponent, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { FeaturePreviewInlineNotification } from '../../../../components/featurePreview/FeaturePreviewInlineNotification'
import { TextField } from '../../../../components/TextField'
import { ContainerConfigurationMessages } from '../../messages'
import { CreateContainerGroupValues } from '../../models'
import { applyPresetConfig } from '../../utils'
import { CodeBlock } from './components/CodeBlock'
import { DockerRunOptions } from './components/DockerRunOptions/DockerRunOptions'
import { DockerRunMessages } from './messages'
import { DockerRunStatusOptions } from './models'
import { mapSupportedDockerOptionsToFormValues } from './utils/DockerMappingFormValues'
import { parseDockerCommand } from './utils/DockerRunParsing'

interface DockerRunFormValues {
  dockerRunCommand: string
}

export interface DockerRunProps {
  /** The function to handle navigation to the previous page */
  onBackButton: () => void
  /** The function to handle card click event. */
  onNextStep: (
    values: Partial<CreateContainerGroupValues>,
    overrideRequiredFields: Partial<Record<keyof CreateContainerGroupValues, boolean>>,
  ) => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   * @param dockerCommand The docker command that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string, dockerCommand?: string) => void
  /**
   * A callback that when executed records a mixpanel page viewed event for the page that was viewed.
   *
   * @param event The event to record
   * @param properties The properties to record
   * @returns
   */
  onTrackMixpanelEvent: (event: string, properties: Dict) => void
  /** The list of available RAM options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
}

export const DockerRun: FunctionComponent<DockerRunProps> = ({
  onBackButton,
  onNextStep,
  onRecordMixpanelElementClickedEvent,
  onTrackMixpanelEvent,
  ramOptions,
  storageOptions,
}) => {
  const intl = useIntl()
  const { control } = useForm<DockerRunFormValues>()

  const dockerRunCommand = useWatch({
    control,
    name: 'dockerRunCommand',
  })

  const parsedResults = useMemo(() => parseDockerCommand(dockerRunCommand, intl), [dockerRunCommand, intl])

  const handleDockerCommandSubmit = async () => {
    const { formValues, overrideRequiredFields, parsedResultsWithFormValues } = mapSupportedDockerOptionsToFormValues({
      parsedResults,
      ramOptions,
      storageOptions,
    })

    for (const parsedItem of parsedResultsWithFormValues) {
      await onTrackMixpanelEvent('Docker Option Selected', {
        label: 'Docker Option',
        'Docker Option': parsedItem.name,
        'Docker Value': parsedItem.value || undefined,
        Output: parsedItem.formValue,
        Supported: parsedItem.isSupported,
        Recommended: parsedItem.isRecommended || false,
        Warning: parsedItem.isWarning || false,
      })
    }
    await onRecordMixpanelElementClickedEvent('Used Docker Run', dockerRunCommand)

    onNextStep(applyPresetConfig(formValues), overrideRequiredFields)
  }

  return (
    <div className="relative h-screen w-full pb-36">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="mb-36 w-full max-w-2xl">
          <div className="mb-4">
            <button className="text-blue-90 underline" onClick={onBackButton}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(ContainerConfigurationMessages.backLinkText)}
            </button>
          </div>
          <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(DockerRunMessages.dockerTitle)}</h1>
          <h3 className="mb-3 text-lg">{intl.formatMessage(DockerRunMessages.dockerDescription)}</h3>
          <div className="mb-10">
            <FeaturePreviewInlineNotification />
          </div>
          <form onSubmit={handleDockerCommandSubmit} className="flex flex-col space-y-4">
            <Controller
              name="dockerRunCommand"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  hasNoDefaultMarginBottom
                  label={intl.formatMessage(DockerRunMessages.label)}
                  placeholder="docker run ..."
                  helperText={intl.formatMessage(DockerRunMessages.detectedOptionsAdjustedText)}
                  isFullWidth
                />
              )}
            />
            {parsedResults.map((result, index) => (
              <DockerRunOptions
                key={`dockerrun-${index}`}
                status={
                  result.isWarning
                    ? DockerRunStatusOptions.OptionWarning
                    : result.isRecommended
                      ? DockerRunStatusOptions.RecommendedOptionNotSelected
                      : result.isSupported
                        ? DockerRunStatusOptions.OptionSelected
                        : DockerRunStatusOptions.OptionNotSupported
                }
                label={
                  <>
                    {result.name !== 'image' && result.name !== 'command' && <CodeBlock value={result.name} />}{' '}
                    {result.description}
                  </>
                }
              />
            ))}
          </form>
        </div>
        <div className="absolute bottom-16 left-0 mt-10 flex w-full flex-col justify-items-end bg-neutral-10 px-6 py-5 align-middle shadow">
          <div className="flex w-full justify-end gap-2">
            <Button variant="green-outlined" type="button" onClick={onBackButton}>
              {intl.formatMessage(DockerRunMessages.previousButtonLabel)}
            </Button>
            <Button variant="green-filled" type="button" onClick={handleDockerCommandSubmit}>
              {intl.formatMessage(DockerRunMessages.nextStepButtonLabel)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
