import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'

interface DrawerContentWrapperProps {
  classes?: string
  children: ReactNode
}

export const DrawerContentWrapper: FunctionComponent<DrawerContentWrapperProps> = ({ children, classes }) => (
  <div
    className={classNames(classes, 'absolute h-full w-full border-solid border-neutral-20 bg-neutral-10 shadow-sm')}
    /* TODO: there was an error being flagged in the old project, but not here -- Particular element can be scrollable and don't require the interactive role.
    link -- https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/93f78856655696a55309440593e0948c6fb96134/docs/rules/no-noninteractive-tabindex.md#case-shouldnt-i-add-a-tabindex-so-that-users-can-navigate-to-this-item */
    tabIndex={0}
  >
    {children}
  </div>
)
