import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { matchRoutes, useLocation, useParams } from 'react-router'
import { trackMixpanelEvent } from '../features/analytics/analyticsSlice'
import { selectLogoutIsPending } from '../features/logout/logoutSelectors'
import { logout } from '../features/logout/logoutSlice'
import { selectIsLeftColumnShowing } from '../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../features/organizations/organizationsSelectors'
import { selectProfileEmail, selectProfileGravatar } from '../features/profile/profileSelectors'
import {
  selectMockIsLeftColumnShowing,
  selectMockOrganization,
  selectMockUserEmail,
} from '../mockFeatures/mockNavigationBarSelectors'
import { useMockAppDispatch, useMockAppSelector } from '../mockFeatures/mockStore'
import { routes } from '../routes/Routes'
import { useAppDispatch, useAppSelector } from '../store'
import { contactSupportViaHelpScoutAnswers } from '../utils/helpScout'
import { NavigationBar } from './NavigationBar'
import { NavigationBarMessages } from './NavigationBar/messages'

export const ConnectedNavigationBar: FunctionComponent = () => {
  let isStorybook = false
  if (window.location.port === '6006' || window.location.host === 'preview-portal-ui.salad.com') {
    isStorybook = true
  }

  return <>{isStorybook ? <ConnectedStorybookNavigationBar /> : <ConnectedAppNavigationBar />}</>
}

const ConnectedStorybookNavigationBar: FunctionComponent = () => {
  // mock store
  const mockDispatch = useMockAppDispatch()
  const mockIsLeftColumnShowing = useMockAppSelector(selectMockIsLeftColumnShowing)
  const mockUserEmail = useMockAppSelector(selectMockUserEmail)
  const mockOrganization = useMockAppSelector(selectMockOrganization)

  return (
    <NavigationBar
      onLeftMenuClick={() => mockDispatch(setLeftColumnShowing({ showing: !mockIsLeftColumnShowing }))}
      onLogOut={() => {}}
      organizationDisplayName={mockOrganization}
      email={mockUserEmail}
      avatarUrl={undefined}
      isLogoutPending={false}
    />
  )
}

const ConnectedAppNavigationBar: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { containerGroupName, jobQueueName, organizationName, projectName, recipeDeploymentName, recipeName } =
    useParams()
  const avatarUrl = useAppSelector(selectProfileGravatar)
  const email = useAppSelector(selectProfileEmail)
  const isLeftColumnShowing = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) =>
    selectOrganizationDisplayName(state, organizationName ?? ''),
  )
  const isLogoutPending = useAppSelector(selectLogoutIsPending)
  const matchedRoutes = matchRoutes(routes, location)
  const currentRouteId = matchedRoutes && matchedRoutes.length > 0 ? matchedRoutes[0]?.route.id : undefined

  const onLeftMenuClick = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: !isLeftColumnShowing }))
  }, [dispatch, isLeftColumnShowing])

  const onLogout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const handleContactSupportViaHelpScoutAnswers = (
    setIsHelpScoutBeaconFailedToLoadModalOpen: (isModalOpen: boolean) => void,
  ) => {
    contactSupportViaHelpScoutAnswers(
      setIsHelpScoutBeaconFailedToLoadModalOpen,
      currentRouteId,
      handleHelpScoutFailedToOpenMixpanelEventTrack,
    )
  }

  const handleHelpScoutFailedToOpenMixpanelEventTrack = useCallback(() => {
    currentRouteId &&
      dispatch(
        trackMixpanelEvent({
          event: 'Event', // TODO: Change this to a more descriptive event name
          properties: {
            label: NavigationBarMessages.helpScoutWidgetFailedViaIconMixpanelEventLabeL.defaultMessage,
            path: currentRouteId,
            organizationName,
            projectName,
            recipeName,
            resourceName: containerGroupName ?? recipeDeploymentName,
            jobQueueName,
          },
        }),
      )
  }, [
    containerGroupName,
    currentRouteId,
    dispatch,
    jobQueueName,
    organizationName,
    projectName,
    recipeDeploymentName,
    recipeName,
  ])

  return (
    <NavigationBar
      avatarUrl={avatarUrl}
      email={email as string}
      isLogoutPending={isLogoutPending}
      onContactSupportViaHelpScoutAnswers={handleContactSupportViaHelpScoutAnswers}
      onLeftMenuClick={onLeftMenuClick}
      onLogOut={onLogout}
      organizationDisplayName={
        organizationName !== undefined ? (organizationDisplayName ?? organizationName) : undefined
      }
    />
  )
}
