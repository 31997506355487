import { defineMessages } from 'react-intl'

export const UnableToLoadGPUOptionsErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to load available GPUs. If this issue persists {contact_support}.',
    id: 'I0mwQsLy',
    description:
      'The description shown in a toast notification message shown when we encounter an error retrieving the available GPU options.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'ZqbuVlE1',
    description:
      'The text shown for the contact support link in the unable to load GPU options error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to load available GPUs',
    id: 'IwSqNkfk',
    description: 'The toast notification title shown when we encounter an error retrieving the available GPU options.',
  },
})
