import { RamOption, StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import CPUCoreOptions from '../../../../../utils/CPUCoreOptions.json'
import { CreateContainerGroupField, CreateContainerGroupValues, ImageType } from '../../../models'
import { ParsedDockerRunItem } from '../models'

interface MapSupportedDockerOptionsToFormValuesParams {
  /** The list of parsed docker run items. */
  parsedResults: ParsedDockerRunItem[]
  /** The list of available RAM options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
}

export const mapSupportedDockerOptionsToFormValues = ({
  parsedResults,
  ramOptions,
  storageOptions,
}: MapSupportedDockerOptionsToFormValuesParams) => {
  const formValues: Partial<CreateContainerGroupValues> = {}
  const overrideRequiredFields: Partial<Record<keyof CreateContainerGroupValues, boolean>> = {
    [CreateContainerGroupField.GPU]: false,
  }

  const parsedResultsWithFormValues = parsedResults.map((result) => {
    let formValue: any = undefined

    if (!result.isSupported) {
      return { ...result, formValue }
    }

    switch (result.name) {
      case '--cpus':
        const requestedCores = parseFloat(result.value as string)
        const closestCores =
          CPUCoreOptions.options.find((option) => option.cores >= requestedCores) || CPUCoreOptions.options[0]
        formValue = closestCores?.cores || 1
        formValues[CreateContainerGroupField.VCPUS] = formValue
        break

      case '-m':
      case '--memory':
        const requestedMemoryFromGbToMB = parseInt(result.value as string, 10) * 1024
        const closestMemory =
          ramOptions?.find((option) => option.value >= requestedMemoryFromGbToMB) ||
          ramOptions?.reduce((prev, curr) => (Math.ceil(curr.value) > Math.ceil(prev.value) ? curr : prev))
        formValue = closestMemory?.value || undefined
        formValues[CreateContainerGroupField.MEMORY] = formValue
        break

      case '--storage-opt':
        const requestedStorageFromGbToByte = parseInt(result.value as string, 10) * 1024 * 1024 * 1024
        const closestStorage =
          storageOptions?.find((option) => option.value >= requestedStorageFromGbToByte) ||
          storageOptions?.reduce((prev, curr) => (Math.ceil(curr.value) > Math.ceil(prev.value) ? curr : prev))
        formValue = closestStorage?.value || undefined
        formValues[CreateContainerGroupField.DISK_SPACE] = formValue
        break

      case '-e':
      case '--env':
        const envVariables = (result.value as string)
          .split(',')
          .map((env) => {
            const [key, val] = env.split('=')
            if (key && key.trim()) {
              return { key: key.trim(), value: val?.trim() || '' }
            }
            return null as any
          })
          .filter((env): env is { key: string; value?: string } => env !== null)

        formValue = envVariables
        formValues[CreateContainerGroupField.ENVIRONMENT_VARIABLES] = [
          ...(formValues[CreateContainerGroupField.ENVIRONMENT_VARIABLES] || []),
          ...envVariables,
        ]
        break

      case '--gpus':
        overrideRequiredFields[CreateContainerGroupField.GPU] = true
        formValue = true
        break

      case '--name':
        formValue = result.value as string
        formValues[CreateContainerGroupField.NAME] = formValue
        break

      case 'image':
        formValue = result.value as string
        formValues[CreateContainerGroupField.IMAGE_SOURCE] = formValue
        formValues[CreateContainerGroupField.IMAGE_TYPE] = ImageType.PUBLIC
        break

      case 'command':
        const commandValues = result.value as string[]
        formValue = commandValues[0]
        formValues[CreateContainerGroupField.COMMAND] = formValue
        formValues[CreateContainerGroupField.COMMAND_ARGUMENTS] = commandValues
          .slice(1)
          .map((arg) => ({ argument: arg }))
        break

      case '-p':
      case '--publish':
        formValue = parseInt(result.value as string, 10)
        formValues[CreateContainerGroupField.CONTAINER_GATEWAY_PORT] = formValue
        formValues[CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED] = true
        formValues[CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION] = false
        break

      default:
        formValue = undefined
        break
    }

    return { ...result, formValue }
  })

  return { parsedResultsWithFormValues, formValues, overrideRequiredFields }
}
