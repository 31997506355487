import { defineMessages } from 'react-intl'

export const ChangeWebhookSecretKeySucceededContentMessages = defineMessages({
  body: {
    defaultMessage:
      'Your old Webhook Secret key will no longer be accepted. Remember to update any uses of this key in your work.',
    id: 'LVp4K5oA',
    description:
      'The description shown in a toast notification message shown when the user successfully changed their Webhook Secret Key.',
  },
  title: {
    defaultMessage: 'Your Webhook Secret key has been changed',
    id: 'tTuO6/69',
    description: 'The toast notification title shown when the user successfully changed their Webhook Secret Key.',
  },
})
