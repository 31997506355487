import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import saladDarkLogoUrl from './assets/salad-logo-dark-4x.png'
import saladLightLogoUrl from './assets/salad-logo-light-4x.png'

import { SaladLogoMessages } from './messages'

export interface SaladLogoProps {
  /** Logo's class names. */
  className?: string
  /** Logo's height. */
  height: number | string
  /** A flag indicating if the Salad text should be dark or light */
  isDark?: boolean
  /** Logo's width. */
  width: number | string
}

export const SaladLogo: FunctionComponent<SaladLogoProps> = ({ className, height, isDark = false, width }) => {
  const intl = useIntl()

  return (
    <img
      alt={intl.formatMessage(SaladLogoMessages.altText)}
      className={className}
      height={height}
      src={isDark ? saladDarkLogoUrl : saladLightLogoUrl}
      width={width}
    />
  )
}
