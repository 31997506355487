import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Spinner } from '../../Spinner'

export interface DropdownMenuItemProps {
  /** The flag indicating whether the dropdown item should have a divider line after it. */
  hasDivider?: boolean
  /** A Font Awesome icon class name (e.g. `fa-envelope`) that will show to the left of the label. */
  iconClassName?: string
  /** A flag indicating if the text will be styled in red. */
  isDanger?: boolean
  /** The flag indicating that the menu item is disabled. */
  isDisabled?: boolean
  /** The label. */
  label: string
  /** Indicates whether related to the menu item process is in progress */
  isLoading?: boolean
  /** On dropdown item click handler. if provided, the menu item will be set as a button element. */
  onClick?: () => void
  /** The path for the link. if provided, the menu item will be set as a link element. */
  path?: string
}

export const DropdownMenuItem: FunctionComponent<DropdownMenuItemProps> = ({
  hasDivider = false,
  iconClassName,
  isDanger = false,
  isDisabled = false,
  isLoading,
  label,
  onClick,
  path,
}) => {
  return (
    <li>
      {onClick && (
        <button
          className={classNames(
            'mb-0 flex w-full items-center truncate p-1.5 px-3 text-start hover:bg-green-10 hover:text-green-60 focus:bg-green-10 focus:text-green-60',
            {
              'border-b-2 border-neutral-50': hasDivider,
              'text-red-70': isDanger,
              'cursor-not-allowed text-neutral-40 hover:bg-neutral-10 hover:text-neutral-60 focus:bg-neutral-10 focus:text-neutral-60':
                isDisabled || isLoading,
            },
          )}
          disabled={isDisabled || isLoading}
          onClick={onClick}
        >
          {iconClassName && !isLoading && (
            <div
              className={classNames(`${iconClassName} mr-2`, {
                'opacity-0': isLoading,
                'text-neutral-40': isDisabled,
              })}
            />
          )}
          {isLoading && <Spinner size="sm" className="mr-2" />}
          {label}
        </button>
      )}
      {path && (
        <NavLink
          to={path}
          className={({ isActive }) =>
            classNames(
              'mb-0 flex w-full items-center truncate p-1.5 px-3 text-start text-neutral-70 hover:bg-green-10 hover:text-green-60 focus:bg-green-10 focus:text-green-60',
              {
                'border-b-2 border-neutral-50': hasDivider,
                'text-red-70': isDanger,
                '!text-green-60': isActive,
              },
            )
          }
        >
          {label}
        </NavLink>
      )}
    </li>
  )
}
