import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { forwardRef, useState } from 'react'

export interface CheckboxProps {
  /** The string for the html property of id. */
  id: string
  /** The flag that indicates if the checkbox is checked. */
  isChecked: boolean
  /** The flag that indicates if the checkbox is disabled. */
  isDisabled?: boolean
  /** The flag for if there is an error. */
  invalid?: boolean
  /** The text that will show beside the checkbox. */
  label?: React.ReactNode
  /** The onChange handler */
  onChange: (checked: boolean) => void
  /** The string for the html property of name. */
  name: string
}

export const Checkbox: FunctionComponent<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, invalid = false, isChecked, isDisabled = false, label, name, onChange }, ref) => {
    const [isFocused, setFocused] = useState<boolean>(false)

    return (
      <div className="flex flex-row leading-4">
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={isChecked}
          disabled={isDisabled}
          ref={ref}
          onChange={() => onChange(!isChecked)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          className={classNames('absolute h-4 w-4 opacity-0', {
            'cursor-pointer': !isDisabled,
          })}
        />
        <div
          className={classNames('mr-2 flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-sm border-2', {
            'bg-neutral-10': !isChecked,
            'border-blue-80': !invalid && !isDisabled,
            'bg-blue-80 focus-within:border-blue-80': isChecked && !isDisabled,
            'border-red-70': invalid,
            'bg-red-70 focus-within:border-red-70': invalid && isChecked && !isDisabled,
            'border-neutral-40': isDisabled,
            'bg-neutral-40 focus-within:border-neutral-40': isDisabled && isChecked,
            'outline outline-2 outline-blue-100': isFocused && !isDisabled,
            'outline outline-2 outline-red-90': isFocused && invalid && !isDisabled,
          })}
        >
          <svg className="size-3 fill-current text-neutral-10" version="1.1" viewBox="0 0 17 12">
            <g fill="none" fillRule="evenodd">
              <g transform="translate(-9 -11)" fill="#FFF" fillRule="nonzero">
                <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
              </g>
            </g>
          </svg>
        </div>
        <label
          className={classNames('ml-1', {
            'text-red-70': invalid,
          })}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
    )
  },
)

Checkbox.displayName = 'Checkbox'
