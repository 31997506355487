import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { ChangeAPIKeyPageMainContent } from './components/ChangeAPIKeyPageMainContent'

export interface ChangeAPIKeyPageProps {
  /** The current API Key. */
  currentAPIKey: string
  /** The flag indicating that the request to change the API key is pending. */
  isChangeAPIKeyRequestPending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user clicks on the Change API Key button. */
  onChangeAPIKey: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
}

export const ChangeAPIKeyPage: FunctionComponent<ChangeAPIKeyPageProps> = ({
  currentAPIKey,
  isChangeAPIKeyRequestPending,
  isLeftColumnOpen,
  onChangeAPIKey,
  onCloseLeftDrawer,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar />}
      MainColumn={
        <ChangeAPIKeyPageMainContent
          currentAPIKey={currentAPIKey}
          isChangeAPIKeyRequestPending={isChangeAPIKeyRequestPending}
          onChangeAPIKey={onChangeAPIKey}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
