import { createSlice } from '@reduxjs/toolkit'
import { onFetchFeatureFlagsFromUnleash } from '../../services'
import { FeatureFlag } from './models'

export interface FeatureFlagsState {
  [key: string]: boolean
}

const initialState: FeatureFlagsState = {
  [FeatureFlag.Container_Group_Priority]: true,
}

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlags(state) {
      const enabledFlags = onFetchFeatureFlagsFromUnleash()
      enabledFlags.map((flag) => {
        return (state[flag] = true)
      })
    },
  },
})

export const { setFeatureFlags } = featureFlagsSlice.actions
