import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { SearchInput } from '../SearchInput'

export interface SearchWithFiltersProps {
  /** Default filter values */
  defaultFilter?: { [key: string]: string | undefined }
  /** The filters being applied to the search. */
  filters: { label: string; options: string[] }[]
  /** The flag indicating if the Search Input should occupy all available width provided by the parent element. */
  isFullWidth?: boolean
  /** The handler to be invoked on any change to either the search input or any of the filters provided */
  onChange: ({ filter, query }: { query: string; filter: { [key: string]: string | undefined } }) => void
  /** The Search Input placeholder. */
  placeholder: string
}

export const SearchWithFilters: FunctionComponent<SearchWithFiltersProps> = ({
  defaultFilter = {},
  filters,
  isFullWidth = false,
  onChange,
  placeholder,
}) => {
  const [filterValues, setFilterValues] = useState(defaultFilter || {})
  const [searchValue, setSearchValue] = useState('')

  const handleFilterChange = (filter: string, value: string) => {
    if (filter === value) {
      const { [filter]: value, ...rest } = filterValues

      setFilterValues(rest)
      onChange({
        query: searchValue,
        filter: rest,
      })
    } else {
      const updatedFilterValues = {
        ...filterValues,
        [filter]: value,
      }

      setFilterValues(updatedFilterValues)
      onChange({
        query: searchValue,
        filter: updatedFilterValues,
      })
    }
  }

  const handleSearchChange = (query: string) => {
    setSearchValue(query)
    onChange({ query, filter: filterValues })
  }

  return (
    <div className="flex gap-6">
      <SearchInput
        onChange={(value) => handleSearchChange(value)}
        placeholder={placeholder}
        isFullWidth={isFullWidth}
      />
      <div className="flex justify-between gap-6">
        {filters.map((filter) => {
          const optionsWithLabel = [filter.label, ...filter.options]
          return (
            <label key={filter.label} htmlFor={filter.label} className="relative flex" aria-label={filter.label}>
              <select
                id={filter.label}
                name={filter.label}
                className="flex flex-1 rounded-md border border-neutral-30 bg-neutral-20 px-4 py-2 text-sm caret-transparent outline-none placeholder:text-neutral-40 hover:border-neutral-30 focus:border-neutral-40 focus:shadow focus:shadow-neutral-40 focus-visible:border-neutral-40"
                onChange={(event) => handleFilterChange(filter.label, event.target.value)}
                value={filterValues[filter.label]}
              >
                {optionsWithLabel.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {/* TODO: Figure out custom caret thing */}
              {/* <div
                className={classNames(
                  'fa-solid fa-chevron-down pointer-events-none absolute right-3 top-3 text-sm text-neutral-100',
                )}
              /> */}
            </label>
          )
        })}
      </div>
    </div>
  )
}
