import { defineMessages } from 'react-intl'

export const HelpScoutBeaconFailedToLoadModalMessages = defineMessages({
  body: {
    defaultMessage: `Something is causing our Help Center to not load properly. This typically occurs when our customers have an ad-blocker or Javascript disabled. To access our in-app support features, check to ensure these conditions aren’t true. If you’re still experiencing errors or want more extensive documentation and guides, please visit the Salad Docs {documentation_link_text}. If you’re looking to contact support, email {contact_support_email}.`,
    id: '5hpKBita',
    description: 'The body text for the modal that appears when the HelpScout Beacon fails to load.',
  },
  closeButtonLabel: {
    defaultMessage: 'Close',
    id: 'jrvtDcCB',
    description: 'The label for the button to close the modal.',
  },
  documentationLinkText: {
    defaultMessage: 'here',
    id: 'ev9OjHAU',
    description: 'The text for the link to the documentation.',
  },
  readDocumentationButtonLabel: {
    defaultMessage: 'Read Documentation',
    id: 'UNyGKYMi',
    description: 'The label for the button to read the documentation.',
  },
  supportEmail: {
    defaultMessage: 'cloud@salad.com',
    id: '05fTXYBY',
    description: 'The email address for support.',
  },
  title: {
    defaultMessage: 'Help Center Failed To Load',
    id: 'PZjaee63',
    description: 'The title for the modal that appears when the HelpScout Beacon fails to load.',
  },
})
