import { defineMessages } from 'react-intl'

export const DockerRunMessages = defineMessages({
  detectedOptionsAdjustedText: {
    defaultMessage: 'All detected options can be adjusted before deploying your container group',
    id: 'hP1ZbfD0',
    description: 'The detected options adjusted text in the bottom bar.',
  },
  dockerDescription: {
    defaultMessage: 'Deploy a container using your current docker run command',
    id: 'DP43FIQi',
    description: 'The description for the Docker modal.',
  },
  dockerTitle: {
    defaultMessage: 'Docker Run',
    id: 'YAw7LmuT',
    description: 'The title for the Docker modal.',
  },
  helperText: {
    defaultMessage: 'Enter your Docker Run command here.',
    id: 'lnQx8BqX',
    description: 'The helper text for the Docker Run command input field.',
  },
  label: {
    defaultMessage: 'Docker Command',
    id: 'NlOcnKlp',
    description: 'The label for the Docker Run command input field.',
  },
  nextStepButtonLabel: {
    defaultMessage: 'Next Step',
    id: 'xQLpHFdn',
    description: 'The label for the next step button in the bottom bar.',
  },
  previousButtonLabel: {
    defaultMessage: 'Previous',
    id: 'E0JVooDD',
    description: 'The label for the previous button in the bottom bar.',
  },
  submitButtonLabel: {
    defaultMessage: 'Deploy',
    id: 'zx4ONXIX',
    description: 'The label for the submit button in the Docker modal.',
  },
})
