import { defineMessages } from 'react-intl'

export const ChangeWebhookSecretKeyFailedContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to change your Webhook Secret key. Try again and if this issue persists {contact_support}.',
    id: 'O4nDc47A',
    description:
      'The description shown in a toast notification message shown when we failed to change their Webhook Secret Key',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'mJWhKEEC',
    description: 'The text shown for the contact support link in the change password error toast notification.',
  },
  title: {
    defaultMessage: 'Error changing Webhook Secret key',
    id: 'yebjVXh7',
    description: 'The toast notification title shown when we failed to change their Webhook Secret Key.',
  },
})
