import { defineMessages } from 'react-intl'

export const LoadWebhookSecretKeyFailedContentMessages = defineMessages({
  body: {
    defaultMessage: 'An error occurred while trying to load your Webhook Secret key. Please try reloading the page.',
    id: '3GMEWSEU',
    description:
      'The description shown in a toast notification message shown when we are unable to load the Webhook Secret Key.',
  },
  title: {
    defaultMessage: 'Error loading Webhook Secret key',
    id: 'l+wITOuF',
    description: 'The toast notification title shown when we are unable to load the Webhook Secret Key.',
  },
})
