import { MessageDescriptor } from 'react-intl'
import { DockerRunParsingMessages } from '../messages'
import { DockerMemoryUnitType, DockerParseResult } from '../models'

const memoryUnitMultiplier: Record<DockerMemoryUnitType, number> = {
  b: 1,
  k: 1024,
  m: 1024 * 1024,
  g: 1024 * 1024 * 1024,
} as Record<string, number>

export const dockerParsePortAction = (portMapping: string): DockerParseResult => {
  let containerPort: string | undefined
  let defaultProtocol = 'tcp'
  let isSupported = true
  let description: MessageDescriptor | undefined
  let value = portMapping

  const mappingParts = portMapping.split(':')

  const portAndProtocol = mappingParts[mappingParts.length - 1]
  if (portAndProtocol) {
    if (portAndProtocol.includes('/')) {
      const [parsedPort, parsedProtocol] = portAndProtocol.split('/')
      value = parsedPort ? parsedPort : portMapping
      defaultProtocol = parsedProtocol ? parsedProtocol.toLowerCase() : ''
    } else {
      value = portAndProtocol
    }

    if (defaultProtocol === 'udp') {
      isSupported = false
      description = DockerRunParsingMessages.unsupportedProtocolUdp
    } else if (containerPort && containerPort.includes('-')) {
      isSupported = false
      description = DockerRunParsingMessages.portRangeNotSupported
    }
  } else {
    isSupported = false
    description = DockerRunParsingMessages.invalidPortFormat
  }

  return {
    isSupported,
    description,
    value,
  }
}

export const dockerParseCpusAction = (value: string): DockerParseResult => {
  const cpuCount = parseFloat(value)
  let isSupported = true
  let description: MessageDescriptor | undefined

  if (isNaN(cpuCount)) {
    isSupported = false
    description = DockerRunParsingMessages.invalidCpuCount
  }

  return {
    isSupported,
    value: isNaN(cpuCount) ? value : cpuCount.toString(),
    description,
  }
}

export const dockerParseMemoryAction = (value: string): DockerParseResult => {
  const unit = value.slice(-1).toLowerCase() as DockerMemoryUnitType
  const amount = parseFloat(value.slice(0, -1))

  if (isNaN(amount)) {
    return {
      isSupported: false,
      value: '0',
      description: DockerRunParsingMessages.invalidMemoryAmount,
    }
  }

  const multiplier = memoryUnitMultiplier[unit]
  if (!multiplier) {
    return {
      isSupported: false,
      value: '0',
      description: DockerRunParsingMessages.storageUnknownUnit,
    }
  }

  const memoryInBytes = amount * multiplier
  const memoryInGB = Math.ceil(memoryInBytes / memoryUnitMultiplier.g)

  return {
    value: memoryInGB.toString(),
  }
}

export const dockerParseGpusAction = (value: string): DockerParseResult => {
  return {
    isSupported: true,
    value,
  }
}

export const dockerParseCommandAction = (entrypoint: string, command: string[]): DockerParseResult => {
  return {
    isSupported: true,
    value: [entrypoint, ...command],
  }
}

export const dockerParseEnvAction = (value: string): DockerParseResult => {
  const [key, val] = value.split('=') as [string, string]
  return {
    isSupported: true,
    value: `${key}=${val || ''}`,
  }
}

export const dockerParseNameAction = (value: string): DockerParseResult => {
  return {
    isSupported: true,
    value,
  }
}

export const dockerParseLogDriverAction = (value: string): DockerParseResult => {
  return {
    isSupported: true,
    value,
  }
}

export const dockerParseLogOptAction = (value: string): DockerParseResult => {
  const [key, val] = value.split('=') as [string, string]
  return {
    isSupported: true,
    value: `${key}=${val}`,
  }
}

export const dockerParseStorageOptAction = (value: string): DockerParseResult => {
  const val = value.split('=')[1]
  let isSupported = true
  let description: MessageDescriptor | undefined
  let storageReturnValue = 0

  if (!val) {
    isSupported = false
    description = DockerRunParsingMessages.storageInvalidFormat
  } else {
    const amount = parseFloat(val.slice(0, -1))
    const unit = val.slice(-1).toLowerCase() as DockerMemoryUnitType
    const multiplier = memoryUnitMultiplier[unit]

    if (isNaN(amount) || !multiplier) {
      isSupported = false
      description = isNaN(amount)
        ? DockerRunParsingMessages.invalidMemoryAmount
        : DockerRunParsingMessages.storageUnknownUnit
    } else {
      storageReturnValue = (amount * multiplier) / memoryUnitMultiplier.g
    }
  }

  return {
    isSupported,
    value: storageReturnValue.toString(),
    description,
  }
}

export const dockerParseRestartAction = (value: string): DockerParseResult => {
  let isSupported = true
  let description: MessageDescriptor | undefined

  if (value !== 'always') {
    isSupported = false
    description = DockerRunParsingMessages.restartPolicyNotSupported
  }
  return {
    isSupported,
    value,
    description,
  }
}

export const dockerParseExposeAction = (value: string): DockerParseResult => {
  return {
    isSupported: true,
    value,
  }
}
