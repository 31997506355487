import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { ContainerGroupLogsQuery } from '@saladtechnologies/openapi-cloud-portal-browser'
import { Dict } from 'mixpanel-browser'

const initialState = {}

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    trackMixpanelEvent(
      _state,
      _action: PayloadAction<{
        event: string
        properties: Dict
      }>,
    ) {},
    trackMixpanelElementClickedEvent(
      _state,
      _action: PayloadAction<{
        label: string
        path: string
        containerGroupLogsQuery?: ContainerGroupLogsQuery
        dockerCommand?: string
        jobQueueName?: string
        organizationName?: string
        projectName?: string
        recipeName?: string
        resourceName?: string
      }>,
    ) {},
    trackMixpanelPageViewedEvent(
      _state,
      _action: PayloadAction<{
        path: string
        instanceId?: string
        organizationName?: string
        projectName?: string
        recipeName?: string
        resourceName?: string
        subPage?: string
      }>,
    ) {},
  },
})

export const { trackMixpanelElementClickedEvent, trackMixpanelEvent, trackMixpanelPageViewedEvent } =
  analyticsSlice.actions
