import { useMemo } from 'react'
import type { ObjectSchema } from 'yup'
import { number, object } from 'yup'
import { ExpirationDelayOptionInSeconds } from '../../../../features/apiKey/models'
import { type ChangeAPIKeyFormValues } from '../../models'

export const useChangeAPIKeyFormValidation = (): ObjectSchema<ChangeAPIKeyFormValues> => {
  const validationScheme = useMemo(
    () =>
      object({
        currentAPIKeyExpirationDelay: number().oneOf(
          Object.values(ExpirationDelayOptionInSeconds).filter((value) => typeof value === 'number'),
        ),
      }),
    [],
  )
  return validationScheme
}
