import { ContainerGroupPriority } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { TimeFrameBasedEstimatedCost } from '../../recipes/TimeFrameBasedEstimatedCost'
import { EstimatedCostMessages } from '../messages'
import type { AllocatedResources } from '../models'
import { formatCostPerHour, getGpuCostPerHourValues } from './utils'

interface EstimatedCostProps {
  /** The selected resource options (GPU, vCPU, and Memory) along with their cost per hour */
  allocatedResources: AllocatedResources
  /** The container group priority */
  priority?: ContainerGroupPriority
  /** The replica count */
  replicaCount: number
}

export const EstimatedCost: FunctionComponent<EstimatedCostProps> = ({
  allocatedResources,
  priority,
  replicaCount,
}) => {
  const intl = useIntl()
  const { cpu, gpuClasses, memory } = allocatedResources

  const cpuCostPerHour = formatCostPerHour(intl, cpu.costPerHour)
  const memoryCostPerHour = formatCostPerHour(intl, memory.costPerHour)
  const gpuCostPerHourValues = getGpuCostPerHourValues(intl, gpuClasses)
  const gpuCostPerHour = gpuCostPerHourValues?.costPerHour || 0
  const costPerHour = (Number(cpuCostPerHour) + Number(memoryCostPerHour) + Number(gpuCostPerHour)) * replicaCount

  return (
    <section className="relative flex w-full flex-col items-end">
      <h2 className="self-start text-2xl font-bold">{intl.formatMessage(EstimatedCostMessages.title)}</h2>
      <p className="mt-2">{intl.formatMessage(EstimatedCostMessages.body)}</p>
      <div className="mb-6 mt-10 flex w-full flex-col border-b-2 border-neutral-40 pb-6">
        {/* CPU Allocated */}
        <div className="mt-4 flex flex-row justify-between">
          <span className="text-xl">
            {intl.formatMessage(EstimatedCostMessages.vCPUAmountLabel, { amount: cpu.cores })}
          </span>
          <span className="text-xl font-bold">
            {intl.formatMessage(EstimatedCostMessages.estimatedCostPerHour, {
              estimated_cost: cpuCostPerHour,
            })}
          </span>
        </div>
        {/* Memory Allocated */}
        <div className="mt-4 flex flex-row justify-between">
          <span className="text-xl">
            {intl.formatMessage(EstimatedCostMessages.memoryCapacityLabel, { capacity_in_gigabytes: memory.gb })}
          </span>
          <span className="text-xl font-bold">
            {intl.formatMessage(EstimatedCostMessages.estimatedCostPerHour, {
              estimated_cost: memoryCostPerHour,
            })}
          </span>
        </div>
        {/* GPU Allocated */}
        {gpuCostPerHourValues && (
          <div className="mt-4 flex flex-row justify-between">
            <div className="flex flex-col items-start">
              <span className="text-xl">{intl.formatMessage(EstimatedCostMessages.gpuCapacityLabel)}</span>
              <span className="text-xs">{intl.formatMessage(EstimatedCostMessages.gpuCapacityDescription)}</span>
            </div>
            <div className="flex flex-col items-end text-right">
              <span className="text-xl font-bold">
                {intl.formatMessage(EstimatedCostMessages.estimatedCostPerHour, {
                  estimated_cost: gpuCostPerHourValues.displayValue,
                })}
              </span>
            </div>
          </div>
        )}
        {/* Priority */}
        {priority && (
          <span className="mt-4 text-lg">
            {intl.formatMessage(EstimatedCostMessages.priorityDescription, {
              priority: capitalizeFirstLetter(priority),
            })}
          </span>
        )}
        {/* Replica Count */}
        <div className="mt-4 flex flex-row justify-between">
          <span className="text-xl">{intl.formatMessage(EstimatedCostMessages.replicaCountLabel)}</span>
          <span className="text-xl font-bold">
            {intl.formatMessage(
              replicaCount > 1
                ? EstimatedCostMessages.replicaCountValuePlural
                : EstimatedCostMessages.replicaCountValueSingular,
              { replica_count: replicaCount },
            )}
          </span>
        </div>
      </div>
      <TimeFrameBasedEstimatedCost costPerHour={costPerHour} hasCostRange={gpuCostPerHourValues?.hasCostRange} />
    </section>
  )
}
