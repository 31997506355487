import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { DockerRunStatusOptions } from '../../models'

interface DockerRunOptionsProps {
  /** The text label to display next to the icon */
  label: ReactNode
  /** The status of the option */
  status: DockerRunStatusOptions
}

export const DockerRunOptions: FunctionComponent<DockerRunOptionsProps> = ({ label, status }) => {
  const getIcon = () => {
    return (
      <div
        className={classNames('flex h-6 w-6 items-center justify-center', {
          'rounded-full border border-red-70': status === DockerRunStatusOptions.OptionNotSupported,
        })}
      >
        <i
          className={classNames('fa-solid', {
            'fa-check text-green-70': status === DockerRunStatusOptions.OptionSelected,
            'fa-xmark text-red-70': status === DockerRunStatusOptions.OptionNotSupported,
            'fa-minus text-neutral-100': status === DockerRunStatusOptions.RecommendedOptionNotSelected,
            'fa-exclamation-triangle text-yellow-70': status === DockerRunStatusOptions.OptionWarning,
          })}
        />
      </div>
    )
  }

  return (
    <div className="flex items-start">
      <div className="mr-2 flex items-center justify-center">{getIcon()}</div>
      <span className="text-neutral-100">{label}</span>
    </div>
  )
}
