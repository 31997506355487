import {
  accountPageRoutePath,
  apiKeyPageRoutePath,
  changePasswordPageRoutePath,
  containerGroupDetailsPageRoutePath,
  containerGroupInstanceDetailsPageRoutePath,
  containerGroupLogsPageRoutePath,
  containersPageRoutePath,
  createContainerGroupPageRoutePath,
  createJobQueuePageRoutePath,
  createOrganizationPageRoutePath,
  createProjectPageRoutePath,
  createRecipePageRoutePath,
  editContainerGroupPageRoutePath,
  editJobQueuePageRoutePath,
  editOrganizationPageRoutePath,
  editProjectPageRoutePath,
  editRecipeDeploymentPageRoutePath,
  inferenceEndpointDetailsPageRoutePath,
  inferenceEndpointsMarketplacePageRoutePath,
  inviteTeamMemberPageRoutePath,
  jobQueueDetailsPageRoutePath,
  jobQueuesPageRoutePath,
  noProjectsAvailablePageRoutePath,
  organizationBillingPageRoutePath,
  organizationsPageRoutePath,
  recipeDeploymentDetailsPageRoutePath,
  recipeDeploymentInstanceDetailsPageRoutePath,
  recipeDeploymentsPageRoutePath,
  recipeDetailsPageRoutePath,
  recipesMarketplacePageRoutePath,
  selectProductPageRoutePath,
  teamPageRoutePath,
} from '../../../routes/routePaths'

export const HelpScoutRoutesAndArticleIds = [
  {
    route: accountPageRoutePath,
    articleIds: ['66ccf04ca62a7505fcf36fc9'],
  },
  {
    route: changePasswordPageRoutePath,
    articleIds: ['66cd03d97b676f2079689aa2'],
  },
  {
    route: apiKeyPageRoutePath,
    articleIds: ['66cd0414a62a7505fcf36ff0'],
  },
  {
    route: organizationsPageRoutePath,
    articleIds: ['66cd043a7b676f2079689aa3'],
  },
  {
    route: createOrganizationPageRoutePath,
    articleIds: ['66cd04640d7d861662423e11'],
  },
  {
    route: editOrganizationPageRoutePath,
    articleIds: ['66cd048f16e6a80d1e1d58b1'],
  },
  {
    route: teamPageRoutePath,
    articleIds: ['66cd052a082392452a0798c8'],
  },
  {
    route: inviteTeamMemberPageRoutePath,
    articleIds: ['66cd05827c359113ce1b0adf'],
  },
  {
    route: organizationBillingPageRoutePath,
    articleIds: ['66cd05c5a62a7505fcf36ff1'],
  },
  {
    route: noProjectsAvailablePageRoutePath,
    articleIds: [],
  },
  {
    route: inferenceEndpointsMarketplacePageRoutePath,
    articleIds: ['66cd063a16e6a80d1e1d58ba'],
  },
  {
    route: inferenceEndpointDetailsPageRoutePath,
    articleIds: ['66cd06857c359113ce1b0ae5'],
  },
  {
    route: createProjectPageRoutePath,
    articleIds: ['66cd06aca62a7505fcf36ff3'],
  },
  {
    route: selectProductPageRoutePath,
    articleIds: ['66cd07827b676f2079689aaf'],
  },
  {
    route: editProjectPageRoutePath,
    articleIds: ['66cd07a20d7d861662423e1d'],
  },
  {
    route: containersPageRoutePath,
    articleIds: ['66cd07caa62a7505fcf36ff9'],
  },
  {
    route: containerGroupDetailsPageRoutePath,
    articleIds: ['66cd07f4082392452a0798ce'],
  },
  {
    route: createContainerGroupPageRoutePath,
    articleIds: ['66cd082d16e6a80d1e1d58c2'],
  },
  {
    route: editContainerGroupPageRoutePath,
    articleIds: ['66cd08667b676f2079689ab5'],
  },
  {
    route: containerGroupLogsPageRoutePath,
    articleIds: ['66cd088d0d7d861662423e20'],
  },
  {
    route: containerGroupInstanceDetailsPageRoutePath,
    articleIds: ['66cd08d27c359113ce1b0ae9'],
  },
  {
    route: recipesMarketplacePageRoutePath,
    articleIds: [],
  },
  {
    route: createRecipePageRoutePath,
    articleIds: [],
  },
  {
    route: recipeDetailsPageRoutePath,
    articleIds: [],
  },
  {
    route: recipeDeploymentsPageRoutePath,
    articleIds: [],
  },
  {
    route: recipeDeploymentDetailsPageRoutePath,
    articleIds: [],
  },
  {
    route: editRecipeDeploymentPageRoutePath,
    articleIds: [],
  },
  {
    route: recipeDeploymentInstanceDetailsPageRoutePath,
    articleIds: [],
  },
  {
    route: jobQueuesPageRoutePath,
    articleIds: ['66cd08ff7b676f2079689ab7'],
  },
  {
    route: createJobQueuePageRoutePath,
    articleIds: ['66cd093ea62a7505fcf36ffc'],
  },
  {
    route: editJobQueuePageRoutePath,
    articleIds: ['66cd096b7b676f2079689abc'],
  },
  {
    route: jobQueueDetailsPageRoutePath,
    articleIds: ['66cd091e0d7d861662423e24'],
  },
]
