import { defineMessages } from 'react-intl'

export const DockerRunParsingMessages = defineMessages({
  commandDockerCmdOnly: {
    defaultMessage:
      'Dockers CMD and ARGs configured as a command to the container. Note that any entrypoint from the Dockerfile will be overridden. {learn_more} {value}',
    id: 'j6Lb2tn1',
    description: 'Message when only Docker CMD is configured.',
  },
  commandDockerEntryPointCmdArg: {
    defaultMessage: 'Docker entrypoint, CMD, and ARG configured as a command to the container. {learn_more} {value}',
    id: 'ekD/RUc5',
    description: 'Message when Docker entrypoint, CMD, and ARG are configured.',
  },
  commandDockerEntryPointOnly: {
    defaultMessage:
      'Docker entrypoint configured as a command to the container. Note that any CMD and ARGs from the Dockerfile will be overridden. {learn_more} {value}',
    id: 'mtZcje6K',
    description: 'Message when only Docker entrypoint is configured.',
  },
  containerImageSpecified: {
    defaultMessage: 'Image source set to {value}',
    id: 'ek3bHlM+',
    description: 'Container image specified',
  },
  invalidCpuCount: {
    defaultMessage: 'Invalid CPU count: {value}',
    id: 'bjNA1j4r',
    description: 'Message when the CPU count provided is invalid.',
  },
  invalidMemoryAmount: {
    defaultMessage: 'Invalid memory amount',
    id: '1KI9gnJR',
    description: 'Message when the memory amount provided is invalid.',
  },
  invalidPortFormat: {
    defaultMessage: 'Invalid port format: {value}',
    id: '5I5Or97y',
    description: 'Message when the port format provided is invalid.',
  },
  learnMoreLinkText: {
    defaultMessage: 'Learn More',
    id: 'J8dLEckf',
    description: 'Text for the learn more link in the Docker run parsing messages.',
  },
  noDockerRunCommand: {
    defaultMessage: 'No docker run command detected',
    id: 'O31rOuwq',
    description: 'Message when no docker run command is detected',
  },
  noImage: {
    defaultMessage: 'No IMAGE detected',
    id: 'Vg97Clz+',
    description: 'Message when no image is specified.',
  },
  portRangeNotSupported: {
    defaultMessage: 'Port ranges are not supported: {value}',
    id: 'TAns4Izm',
    description: 'Message when port ranges are not supported.',
  },
  recommendedOptionMissing: {
    defaultMessage: 'Recommended but missing',
    id: 'Hm5Sr4+b',
    description: 'Message when a recommended Docker option is missing.',
  },
  restartPolicyNotSupported: {
    defaultMessage: 'Restart policies other than -always are not supported at this time',
    id: 'NW0AH1gV',
    description: 'Message when a restart policy other than -always is not supported.',
  },
  storageInvalidFormat: {
    defaultMessage: 'Invalid format for storage option',
    id: 'bdAn+a7j',
    description: 'Message when the storage option format is invalid.',
  },
  storageUnknownUnit: {
    defaultMessage: 'Unknown storage unit',
    id: 'qBZNx4Os',
    description: 'Message when an unknown storage unit is provided.',
  },
  unsupportedProtocolUdp: {
    defaultMessage: 'UDP is not supported: {value}',
    id: 'expgMzyd',
    description: 'Message when UDP protocol is not supported.',
  },
})
