import { ContainerGroupPriority, GpuClass } from '@saladtechnologies/openapi-cloud-portal-browser'

/**
 * Returns the price for a GPU class based on the selected Container Group Priority. If the priority is not provided, it
 * will default to the High priority price. This
 *
 * @param gpuClass The GPU Class.
 * @returns The price for the GPU class based on the selected Container Group Priority.
 */
export const getRecipeGpuPrice = (gpuClass: GpuClass): number => {
  // @ts-ignore - deprecated price, but will still need to support it until API is updated to match current OAS
  const deprecatedPrice = gpuClass['price']
  if (deprecatedPrice !== undefined) {
    return parseFloat(deprecatedPrice)
  }
  const price = gpuClass.prices.find((price) => price.priority === ContainerGroupPriority.High)?.price
  return price ? parseFloat(price) : 0
}
