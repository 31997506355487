import classNames from 'classnames'
import { type FunctionComponent, type ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { ProgressPillMessages } from './messages'
import { getBackgroundProgressBarStyles } from './utils'

export interface ProgressPillProps {
  /** The icon that will be displayed to the left of the text provided. */
  icon: ReactNode
  /** The label for the `ProgressPill`. */
  label: string
  /** The percentage of the progress made. */
  percentage?: number
  /**
   * The width(px) of the pill. If not provided, when in a progress state the pill will take the width of the parent
   * element.
   */
  width?: number
  /** The callback executed when the user clicks the status */
  onClick?: () => void
}

export const ProgressPill: FunctionComponent<ProgressPillProps> = ({ icon, label, onClick, percentage, width }) => {
  const intl = useIntl()
  const labelAndIconElementId = 'label-and-icon'

  if (percentage === undefined) {
    return (
      <div
        className={classNames('size-6 rounded-full border-DEFAULT border-neutral-30 text-xs font-medium', {
          'cursor-help': onClick !== undefined,
        })}
        onClick={onClick}
      >
        <div className="flex size-full items-center justify-center">
          <div className="size-4 rounded-full border-2 border-neutral-50" />
        </div>
      </div>
    )
  }

  if (percentage >= 100) {
    return (
      <div
        className={classNames('size-6 rounded-full bg-green-10 text-xs font-medium', {
          'cursor-help': onClick !== undefined,
        })}
        onClick={onClick}
      >
        <div className="flex size-full items-center justify-center text-green-100">
          <i className={classNames('fa-solid fa-check')} />
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames('relative h-6 rounded-full border-DEFAULT border-neutral-30 text-xs font-medium', {
        'cursor-help': onClick !== undefined,
      })}
      style={{ width: `${width}px` ?? 'auto' }}
      onClick={onClick}
    >
      <div className="h-full rounded-full bg-green-10" style={getBackgroundProgressBarStyles(percentage)} />
      <div
        className="absolute inset-0 flex h-full w-fit items-center rounded-full px-2 py-1 text-green-100"
        id={labelAndIconElementId}
      >
        {icon}
        <p className="ml-2 uppercase">
          {intl.formatMessage(ProgressPillMessages.labelWithPercentage, {
            label,
            percentage,
          })}
        </p>
      </div>
    </div>
  )
}
