import { defineMessages } from 'react-intl'

export const ChangeWebhookSecretKeyModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'hSDSvM84',
    description: `The label for the button that closes the Change Webhook Secret Key Modal`,
  },
  changeWebhookSecretKeyButtonLabel: {
    defaultMessage: 'Change Key',
    id: '1WCauMT9',
    description: `The label for the button that changes a user's Webhook Secret Key.`,
  },
  description: {
    defaultMessage: 'You cannot undo this, and your old key will immediately stop working.',
    id: '/cJSPtAz',
    description: 'The description for the modal that shows when a user wants to change their Webhook secret key.',
  },
  title: {
    defaultMessage: 'Are you sure you want to change your Webhook Secret Key?',
    id: 'Zu5eFjDd',
    description: 'The title for the modal that shows when a user wants to change their Webhook secret key.',
  },
})
