// Organization Pages
export const getOrganizationsPagePath = () => `/organizations`

export const getCreateOrganizationsPagePath = () => `/organizations/new`

export const getOrganizationBillingPagePath = (organizationName: string) => `/organizations/${organizationName}/billing`

export const getOrganizationEditPagePath = (organizationName: string) => `/organizations/${organizationName}/edit`

export const getOrganizationWebhookSecretKeyPagePath = (organizationName: string) =>
  `/organizations/${organizationName}/webhook-secret-key`

export const getOrganizationTeamPagePath = (organizationName: string) => `/organizations/${organizationName}/team`

export const getInviteTeamMemberPagePath = (organizationName: string) =>
  `/organizations/${organizationName}/team/invite`

// Inference Endpoint Pages
export const getInferenceEndpointsMarketplacePagePath = (organizationName: string) =>
  `/organizations/${organizationName}/inference-endpoints`

export const getInferenceEndpointDetailsPagePath = (organizationName: string, inferenceEndpointName: string) =>
  `/organizations/${organizationName}/inference-endpoints/${inferenceEndpointName}`

// Project Pages
export const getCreateProjectPagePath = (organizationName: string) => `/organizations/${organizationName}/projects/new`

export const getEditProjectPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/edit`

export const getSelectProductPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}`

export const getNoProjectsPagePath = (organizationName: string) => `/organizations/${organizationName}/no-projects`

// Container Group Pages
export const getContainerGroupDetailsPagePath = (
  organizationName: string,
  projectName: string,
  containerGroupName: string,
) => `/organizations/${organizationName}/projects/${projectName}/containers/${containerGroupName}`

export const getInstancePath = (pageDetailPath: string, instanceId: string) =>
  `${pageDetailPath}/instances/${instanceId}`

export const getContainerGroupsPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/containers`

export const getCreateContainerGroupPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/containers/new`

export const getEditContainerGroupPagePath = (
  organizationName: string,
  projectName: string,
  containerGroupName: string,
) => `/organizations/${organizationName}/projects/${projectName}/containers/${containerGroupName}/edit`

// Recipe Pages
export const getCreateRecipeDeploymentPagePath = (organizationName: string, projectName: string, recipeName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/recipes/${recipeName}/new`

export const getEditRecipePagePath = (organizationName: string, projectName: string, recipeDeploymentName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/recipe-deployments/${recipeDeploymentName}/edit`

export const getRecipeDeploymentDetailsPagePath = (
  organizationName: string,
  projectName: string,
  recipeDeploymentName: string,
) => `/organizations/${organizationName}/projects/${projectName}/recipe-deployments/${recipeDeploymentName}`

export const getRecipeDeploymentsPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/recipe-deployments`

export const getRecipeDetailsPagePath = (organizationName: string, projectName: string, recipeName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/recipes/${recipeName}`

export const getRecipeMarketplacePagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/recipes`

// Job Queue Pages
export const getCreateJobQueuePagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/job-queues/new`

export const getEditJobQueuePagePath = (organizationName: string, projectName: string, jobQueueName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/job-queues/${jobQueueName}/edit`

export const getJobQueueDetailsPagePath = (organizationName: string, projectName: string, jobQueueName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/job-queues/${jobQueueName}`

export const getJobQueuesPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/job-queues`
