import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { UserAccountsAPI } from '../apiMethods'
import { getApiKey, setApiKey, updateApiKey } from '../features/apiKey/apiKeySlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getChangeAPIKeyFailedContent,
  getChangeAPIKeySucceededContent,
  getLoadAPIKeyFailedContent,
} from '../notifications/clientToastNotificationContent/apiKey'
import { apiKeyPageRoutePath } from '../routes/routePaths'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetApiKey: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getApiKey.match),
    mergeMap(() => {
      return concat(
        of(setRequestStatus({ request: 'getApiKey', status: 'pending' })),
        from(UserAccountsAPI.getApikey()).pipe(
          mergeMap((response) => {
            return concat(
              of(
                setApiKey({
                  apiKey: response.key,
                }),
                setRequestStatus({ request: 'getApiKey', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getApiKey', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() => {
            return concat(
              of(
                showToastNotification(getLoadAPIKeyFailedContent(intl)),

                setRequestStatus({ request: 'getApiKey', status: 'failed' }),
              ),
              of(setRequestStatus({ request: 'getApiKey', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      )
    }),
  )

export const onUpdateApiKey: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(updateApiKey.match),
    mergeMap(({ payload: { expirationDelay } }) => {
      return concat(
        of(setRequestStatus({ request: 'updateApiKey', status: 'pending' })),
        from(
          UserAccountsAPI.updateApikey({
            updateApiKey: {
              expirationDelay,
            },
          }),
        ).pipe(
          mergeMap((response) => {
            return concat(
              of(
                setApiKey({
                  apiKey: response.key,
                }),
                setRequestStatus({ request: 'updateApiKey', status: 'succeeded' }),
                showToastNotification(getChangeAPIKeySucceededContent(intl)),
                navigateTo({ path: apiKeyPageRoutePath }),
              ),
              of(setRequestStatus({ request: 'updateApiKey', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() => {
            return concat(
              of(
                setRequestStatus({ request: 'updateApiKey', status: 'failed' }),
                showToastNotification(getChangeAPIKeyFailedContent(intl)),
              ),
              of(setRequestStatus({ request: 'updateApiKey', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      )
    }),
  )
