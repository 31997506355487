import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { ExpirationDelayOptionInSeconds } from './models'

export interface apiKeyState {
  apiKey: string
}

const initialState: apiKeyState = {
  apiKey: '',
}

export const apiKeySlice = createSlice({
  name: 'apiKey',
  initialState,
  reducers: {
    getApiKey() {},
    setApiKey(state, action: PayloadAction<{ apiKey: string }>) {
      state.apiKey = action.payload.apiKey
    },
    updateApiKey(_state, _action: PayloadAction<{ expirationDelay?: ExpirationDelayOptionInSeconds }>) {},
  },
})

export const { getApiKey, setApiKey, updateApiKey } = apiKeySlice.actions
