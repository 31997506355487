import { defineMessages } from 'react-intl'

export const EstimatedCostMessages = defineMessages({
  body: {
    defaultMessage: 'Final cost is determined from the duration each container instance is running.',
    id: 'RUUBQ54z',
    description: 'Estimated Cost description',
  },
  estimatedCostPerHour: {
    // eslint-disable-next-line no-template-curly-in-string -- need dollar sign for cost_per_hour
    defaultMessage: '+ ${estimated_cost} per hour',
    id: 'Uy5drl5D',
    description: 'The value for the estimated cost per hour.',
  },
  gpuCapacityLabel: {
    defaultMessage: 'GPU',
    id: 'GpQiIjCb',
    description: 'GPU capacity label',
  },
  gpuCapacityDescription: {
    defaultMessage: 'Costs are based off actual GPU usage',
    id: 'W3TtDGBi',
    description: 'GPU capacity description',
  },
  memoryCapacityLabel: {
    defaultMessage: '{capacity_in_gigabytes} GB',
    id: '2Taynpmt',
    description: 'Memory capacity in gigabytes',
  },
  perHour: {
    defaultMessage: 'per hour',
    id: 'IjHilaJF',
    description: 'per hour',
  },
  priorityDescription: {
    defaultMessage: '{priority} Priority',
    id: 'o90Jit85',
    description: 'Priority description',
  },
  replicaCountLabel: {
    defaultMessage: 'Replica Count',
    id: 'VFwjUqVq',
    description: 'Replica Count label',
  },
  replicaCountValuePlural: {
    defaultMessage: 'x {replica_count} replicas',
    id: 'RUByD80/',
    description: 'Replica Count',
  },
  replicaCountValueSingular: {
    defaultMessage: 'x {replica_count} replica',
    id: 'LX3JBY6/',
    description: 'Replica Count',
  },
  title: {
    defaultMessage: 'Estimated Cost',
    id: 'ljA3tym3',
    description: 'Estimated Cost title',
  },
  vCPUAmountLabel: {
    defaultMessage: '{amount} vCPU',
    id: 'jrxUvSJ5',
    description: 'amount of vCPU',
  },
})
