import {
  ContainerGroupPriority,
  ContainerLoggingHttpCompressionEnum,
  ContainerLoggingHttpFormatEnum,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { array, boolean, mixed, number, object, string } from 'yup'
import { ExternalLoggingService } from '../../components/containerGroups/models'
import { isNonrequiredImageSourceValid, isRequiredImageSourceValid } from '../../utils/formValidation'
import { ImageSourceInputMessages } from '../CreateContainerGroup/messages'
import {
  EditAWSElasticInputFieldsMessages,
  EditAxiomInputFieldsMessages,
  EditAzureInputFieldsMessages,
  EditCommandFieldsMessages,
  EditDatadogInputFieldsMessages,
  EditDisplayNameInputMessages,
  EditDockerHubInputFieldsMessages,
  EditEnvironmentVariablesFieldsMessages,
  EditGitHubInputFieldsMessages,
  EditHTTPInputFieldsMessages,
  EditHealthProbeComponentMessages,
  EditHealthProbeHeadersSidePanelModalMessages,
  EditNewRelicInputFieldsMessages,
  EditQuayInputFieldsMessages,
  EditSelfHostedInputFieldsMessages,
  EditSplunkInputFieldsMessages,
  EditTCPInputFieldsMessages,
} from './messages'
import type { EditContainerGroupValues } from './models'
import { EditContainerGroupField, ImageType, PrivateRegistryProvider, ProtocolOptions } from './models'

export const useEditContainerGroupFormValidation = (
  maxReplicaCount: number,
): ObjectSchema<EditContainerGroupValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object().shape(
        {
          // [EditContainerGroupField.AUTOSTART_POLICY]: boolean().required(),
          [EditContainerGroupField.AWS_ELASTIC_ACCESS_KEY_ID]: string().when(
            EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.AWS_ECR,
              then: (schema) =>
                schema.required(intl.formatMessage(EditAWSElasticInputFieldsMessages.accessKeyIDRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.AWS_ELASTIC_SECRET_ACCESS_KEY]: string().when(
            EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.AWS_ECR,
              then: (schema) =>
                schema.required(intl.formatMessage(EditAWSElasticInputFieldsMessages.secretAccessKeyRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.AXIOM_API_KEY]: string().when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.AXIOM,
            then: (schema) =>
              schema
                .required(intl.formatMessage(EditAxiomInputFieldsMessages.apiKeyRequiredText))
                .max(1000, intl.formatMessage(EditAxiomInputFieldsMessages.apiKeyMaxLengthText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.AXIOM_DATASET_NAME]: string().when(
            EditContainerGroupField.EXTERNAL_LOGGING_SERVICE,
            {
              is: ExternalLoggingService.AXIOM,
              then: (schema) =>
                schema
                  .required(intl.formatMessage(EditAxiomInputFieldsMessages.datasetNameRequiredText))
                  .max(1000, intl.formatMessage(EditAxiomInputFieldsMessages.apiKeyMaxLengthText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.AXIOM_HOST]: string().when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.AXIOM,
            then: (schema) =>
              schema
                .required(intl.formatMessage(EditAxiomInputFieldsMessages.hostRequiredText))
                .matches(/^(?!https?:\/\/).*$/, intl.formatMessage(EditAxiomInputFieldsMessages.hostValidationText))
                .max(1000, intl.formatMessage(EditAxiomInputFieldsMessages.apiKeyMaxLengthText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.AZURE_PASSWORD]: string().when(EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER, {
            is: PrivateRegistryProvider.AZURE_CONTAINER,
            then: (schema) => schema.required(intl.formatMessage(EditAzureInputFieldsMessages.passwordRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.AZURE_USERNAME]: string().when(EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER, {
            is: PrivateRegistryProvider.AZURE_CONTAINER,
            then: (schema) => schema.required(intl.formatMessage(EditAzureInputFieldsMessages.usernameRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.COMMAND]: string().when(EditContainerGroupField.COMMAND_ARGUMENTS, {
            is: (commandArguments: { argument: string }[] | undefined) =>
              commandArguments && commandArguments?.length > 0,
            then: (schema) =>
              schema
                .matches(/^(?!docker).*$/, intl.formatMessage(EditCommandFieldsMessages.dockerErrorMessage))
                .required(intl.formatMessage(EditCommandFieldsMessages.commandFieldErrorMessage)),
            otherwise: (schema) =>
              schema
                .matches(/^(?!docker).*$/, intl.formatMessage(EditCommandFieldsMessages.dockerErrorMessage))
                .notRequired(),
          }),
          [EditContainerGroupField.COMMAND_ARGUMENTS]: array().of(object().shape({ argument: string() })),
          [EditContainerGroupField.DATADOG_API]: string().when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.DATADOG,
            then: (schema) => schema.required(intl.formatMessage(EditDatadogInputFieldsMessages.apiKeyRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.DATADOG_HOST]: string().when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.DATADOG,
            then: (schema) =>
              schema
                .matches(/^(?!https?:\/\/).*$/, intl.formatMessage(EditDatadogInputFieldsMessages.hostValidationText))
                .required(intl.formatMessage(EditDatadogInputFieldsMessages.hostRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.DATADOG_TAGS]: array().of(
            object().shape({
              name: string().required(intl.formatMessage(EditDatadogInputFieldsMessages.nameRequiredText)),
              value: string().required(intl.formatMessage(EditDatadogInputFieldsMessages.valueRequiredText)),
            }),
          ),
          [EditContainerGroupField.DISK_SPACE]: number(),
          [EditContainerGroupField.DISPLAY_NAME]: string()
            .required(intl.formatMessage(EditDisplayNameInputMessages.required))
            .matches(/^[a-z][a-z0-9-]{0,61}[a-z0-9]$/, intl.formatMessage(EditDisplayNameInputMessages.nameRegexError))
            .min(2, intl.formatMessage(EditDisplayNameInputMessages.minMaxErrorMessage))
            .max(63, intl.formatMessage(EditDisplayNameInputMessages.minMaxErrorMessage)),
          [EditContainerGroupField.DOCKER_HUB_PERSONAL_ACCESS_TOKEN]: string().when(
            EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.DOCKER_HUB,
              then: (schema) =>
                schema.required(intl.formatMessage(EditDockerHubInputFieldsMessages.personalAccessTokenRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.DOCKER_HUB_USERNAME]: string().when(
            EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.DOCKER_HUB,
              then: (schema) =>
                schema.required(intl.formatMessage(EditDockerHubInputFieldsMessages.usernameRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.ENVIRONMENT_VARIABLES]: array()
            .of(
              object().shape({
                key: string()
                  .matches(/^[A-Za-z]\w*$/, intl.formatMessage(EditEnvironmentVariablesFieldsMessages.keyInvalid))
                  .required(intl.formatMessage(EditEnvironmentVariablesFieldsMessages.keyRequiredText)),
                value: string(),
              }),
            )
            .test({
              message: intl.formatMessage(EditEnvironmentVariablesFieldsMessages.keyMustBeUnique),
              test: (value) => value?.length === new Set(value?.map((item) => item.key)).size,
            }),
          [EditContainerGroupField.EXTERNAL_LOGGING_SERVICE]: string().oneOf(Object.values(ExternalLoggingService)),
          [EditContainerGroupField.GIT_HUB_PERSONAL_ACCESS_TOKEN]: string().when(
            EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.GITHUB_CONTAINER,
              then: (schema) =>
                schema.required(intl.formatMessage(EditGitHubInputFieldsMessages.personalAccessTokenRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.GIT_HUB_USERNAME]: string().when(EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER, {
            is: PrivateRegistryProvider.GITHUB_CONTAINER,
            then: (schema) => schema.required(intl.formatMessage(EditGitHubInputFieldsMessages.usernameRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY]: mixed<FileList>().when(
            EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.GOOGLE_ARTIFACT_REGISTRY,
              then: (schema) =>
                schema.required().test((value) => {
                  return value.length > 0
                }),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.GOOGLE_CONTAINER_JSON_KEY]: mixed<FileList>().when(
            EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.GOOGLE_CONTAINER_REGISTRY,
              then: (schema) =>
                schema.required().test((value) => {
                  return value.length > 0
                }),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.GPU]: array().of(string().required()),
          [EditContainerGroupField.HTTP_COMPRESSION]: string()
            .oneOf(Object.values(ContainerLoggingHttpCompressionEnum))
            .when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
              is: ExternalLoggingService.HTTP,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.HTTP_FORMAT]: string()
            .oneOf(Object.values(ContainerLoggingHttpFormatEnum))
            .when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
              is: ExternalLoggingService.HTTP,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.HTTP_HEADERS]: array()
            .of(
              object().shape({
                name: string()
                  .required(intl.formatMessage(EditHTTPInputFieldsMessages.nameRequiredErrorText))
                  .max(128, intl.formatMessage(EditHTTPInputFieldsMessages.maxCharacterErrorText)),
                value: string()
                  .required(intl.formatMessage(EditHTTPInputFieldsMessages.valueRequiredErrorText))
                  .max(128, intl.formatMessage(EditHTTPInputFieldsMessages.maxCharacterErrorText)),
              }),
            )
            .when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
              is: ExternalLoggingService.HTTP,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.HTTP_HOST]: string().when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.HTTP,
            then: (schema) =>
              schema
                .required(intl.formatMessage(EditHTTPInputFieldsMessages.hostRequiredText))
                .matches(/^(?!https?:\/\/).*$/, intl.formatMessage(EditHTTPInputFieldsMessages.hostValidationText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.HTTP_PASSWORD]: string().when(
            [
              EditContainerGroupField.EXTERNAL_LOGGING_SERVICE,
              EditContainerGroupField.HTTP_USER,
              EditContainerGroupField.HTTP_PASSWORD,
            ],
            {
              is: (
                externalLoggingService: ExternalLoggingService,
                user: string | undefined,
                password: string | undefined,
              ) => externalLoggingService === ExternalLoggingService.HTTP && user && !password,
              then: (schema) => schema.required(intl.formatMessage(EditHTTPInputFieldsMessages.passwordRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.HTTP_PATH]: string(),
          [EditContainerGroupField.HTTP_PORT]: number()
            .min(1)
            .max(65535)
            .when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
              is: ExternalLoggingService.HTTP,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.HTTP_USER]: string().when(
            [
              EditContainerGroupField.EXTERNAL_LOGGING_SERVICE,
              EditContainerGroupField.HTTP_PASSWORD,
              EditContainerGroupField.HTTP_USER,
            ],
            {
              is: (
                externalLoggingService: ExternalLoggingService,
                password: string | undefined,
                user: string | undefined,
              ) => externalLoggingService === ExternalLoggingService.HTTP && password && !user,
              then: (schema) => schema.required(intl.formatMessage(EditHTTPInputFieldsMessages.userRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.IMAGE_SOURCE]: string().when(EditContainerGroupField.IMAGE_TYPE, {
            is: ImageType.PRIVATE,
            then: (schema) =>
              schema
                .required(intl.formatMessage(ImageSourceInputMessages.imageSourceRequiredText))
                .test(
                  'is-generally-valid-container-image-name',
                  intl.formatMessage(ImageSourceInputMessages.imageSourceInvalidText),
                  (value) => isRequiredImageSourceValid(value),
                ),
            otherwise: (schema) =>
              schema.test(
                'is-generally-valid-container-image-name',
                intl.formatMessage(ImageSourceInputMessages.imageSourceInvalidText),
                (value) => isNonrequiredImageSourceValid(value),
              ),
          }),

          [EditContainerGroupField.IMAGE_TYPE]: string().oneOf([ImageType.PUBLIC, ImageType.PRIVATE]).required(),
          [EditContainerGroupField.JOB_QUEUE]: string(),
          [EditContainerGroupField.JOB_QUEUE_PATH]: string().when(EditContainerGroupField.JOB_QUEUE, {
            is: (jobQueue: string | undefined) => jobQueue !== undefined,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.JOB_QUEUE_PORT]: number()
            .min(0)
            .max(65535)
            .when(EditContainerGroupField.JOB_QUEUE, {
              is: (jobQueue: string | undefined) => jobQueue !== undefined,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_COMMAND]: string()
            .required()
            .when([EditContainerGroupField.LIVENESS_PROBE_ENABLED, EditContainerGroupField.LIVENESS_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.EXEC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_COMMAND_ARGUMENTS]: array().of(
            object().shape({ argument: string().required() }),
          ),
          [EditContainerGroupField.LIVENESS_PROBE_ENABLED]: boolean(),
          [EditContainerGroupField.LIVENESS_PROBE_FAILURE_THRESHOLD]: number()
            .min(1)
            .max(65535)
            .required()
            .when(EditContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_HEADERS]: array()
            .of(
              object().shape({
                name: string()
                  .required(intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.nameRequiredErrorText))
                  .max(128, intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
                value: string()
                  .required(intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.valueRequiredErrorText))
                  .max(128, intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
              }),
            )
            .when([EditContainerGroupField.LIVENESS_PROBE_ENABLED, EditContainerGroupField.LIVENESS_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_INITIAL_DELAY_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(EditContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_PATH]: string()
            .required(intl.formatMessage(EditHealthProbeComponentMessages.pathRequiredText))
            .when([EditContainerGroupField.LIVENESS_PROBE_ENABLED, EditContainerGroupField.LIVENESS_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
              then: (schema) =>
                schema
                  .required(intl.formatMessage(EditHealthProbeComponentMessages.pathRequiredText))
                  .matches(/^\/.*/, intl.formatMessage(EditHealthProbeComponentMessages.pathInvalidMessage)),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_PERIOD_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(EditContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_PORT]: number()
            .min(1)
            .max(65535)
            .required()
            .when([EditContainerGroupField.LIVENESS_PROBE_ENABLED, EditContainerGroupField.LIVENESS_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol !== ProtocolOptions.EXEC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_PROTOCOL]: string()
            .oneOf(Object.values(ProtocolOptions))
            .required()
            .when(EditContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_SERVICE]: string()
            .max(128)
            .required()
            .when([EditContainerGroupField.LIVENESS_PROBE_ENABLED, EditContainerGroupField.LIVENESS_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.GRPC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_SUCCESS_THRESHOLD]: number()
            .min(1)
            .max(10000)
            .required()
            .when(EditContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.LIVENESS_PROBE_TIMEOUT_SECONDS]: number()
            .min(1)
            .max(10000)
            .required()
            .when(EditContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.MEMORY]: number().required(),
          [EditContainerGroupField.CONTAINER_GATEWAY_ENABLED]: boolean(),
          [EditContainerGroupField.CONTAINER_GATEWAY_PORT]: number()
            .min(1)
            .max(65535)
            .required()
            .when(EditContainerGroupField.CONTAINER_GATEWAY_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION]: boolean()
            .required()
            .when(EditContainerGroupField.CONTAINER_GATEWAY_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.NEW_RELIC_HOST]: string().when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.NEW_RELIC,
            then: (schema) =>
              schema
                .required(intl.formatMessage(EditNewRelicInputFieldsMessages.hostRequiredText))
                .max(1000, intl.formatMessage(EditNewRelicInputFieldsMessages.hostMaxCharactersText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.NEW_RELIC_INGESTION_KEY]: string().when(
            EditContainerGroupField.EXTERNAL_LOGGING_SERVICE,
            {
              is: ExternalLoggingService.NEW_RELIC,
              then: (schema) =>
                schema
                  .required(intl.formatMessage(EditNewRelicInputFieldsMessages.ingestionKeyRequiredText))
                  .max(1000, intl.formatMessage(EditNewRelicInputFieldsMessages.ingestionKeyMaxCharactersText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.PRIORITY]: string().oneOf(Object.values(ContainerGroupPriority)),
          [EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER]: string().oneOf(Object.values(PrivateRegistryProvider)),
          [EditContainerGroupField.READINESS_PROBE_COMMAND]: string()
            .required()
            .when([EditContainerGroupField.READINESS_PROBE_ENABLED, EditContainerGroupField.READINESS_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.EXEC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS]: array().of(
            object().shape({ argument: string().required() }),
          ),
          [EditContainerGroupField.READINESS_PROBE_ENABLED]: boolean(),
          [EditContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]: number()
            .min(0)
            .max(10000)
            .required()
            .when(EditContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.READINESS_PROBE_HEADERS]: array()
            .of(
              object().shape({
                name: string()
                  .required(intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.nameRequiredErrorText))
                  .max(128, intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
                value: string()
                  .required(intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.valueRequiredErrorText))
                  .max(128, intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
              }),
            )
            .when([EditContainerGroupField.READINESS_PROBE_ENABLED, EditContainerGroupField.READINESS_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(EditContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.READINESS_PROBE_PORT]: number()
            .min(1)
            .max(65535)
            .required()
            .when([EditContainerGroupField.READINESS_PROBE_ENABLED, EditContainerGroupField.READINESS_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol !== ProtocolOptions.EXEC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.READINESS_PROBE_PATH]: string()
            .required(intl.formatMessage(EditHealthProbeComponentMessages.pathRequiredText))
            .when([EditContainerGroupField.READINESS_PROBE_ENABLED, EditContainerGroupField.READINESS_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
              then: (schema) =>
                schema
                  .required(intl.formatMessage(EditHealthProbeComponentMessages.pathRequiredText))
                  .matches(/^\/.*/, intl.formatMessage(EditHealthProbeComponentMessages.pathInvalidMessage)),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(EditContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.READINESS_PROBE_PROTOCOL]: string()
            .oneOf(Object.values(ProtocolOptions))
            .required()
            .when(EditContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.READINESS_PROBE_SERVICE]: string()
            .max(128)
            .required()
            .when([EditContainerGroupField.READINESS_PROBE_ENABLED, EditContainerGroupField.READINESS_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.GRPC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]: number()
            .min(1)
            .max(10000)
            .required()
            .when(EditContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]: number()
            .min(1)
            .max(10000)
            .required()
            .when(EditContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.REPLICA_COUNT]: number().min(0).max(maxReplicaCount).required(),
          [EditContainerGroupField.SELF_HOSTED_PASSWORD]: string().when(
            EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.SELF_HOSTED,
              then: (schema) =>
                schema.required(intl.formatMessage(EditSelfHostedInputFieldsMessages.passwordRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.SELF_HOSTED_USERNAME]: string().when(
            EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.SELF_HOSTED,
              then: (schema) =>
                schema.required(intl.formatMessage(EditSelfHostedInputFieldsMessages.usernameRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [EditContainerGroupField.SPLUNK_HOST]: string().when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.SPLUNK,
            then: (schema) =>
              schema
                .required(intl.formatMessage(EditSplunkInputFieldsMessages.hostRequiredText))
                .max(1000, intl.formatMessage(EditSplunkInputFieldsMessages.hostMaxCharactersText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.SPLUNK_TOKEN]: string().when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.SPLUNK,
            then: (schema) => schema.required(intl.formatMessage(EditSplunkInputFieldsMessages.tokenRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.STARTUP_PROBE_COMMAND]: string()
            .required()
            .when([EditContainerGroupField.STARTUP_PROBE_ENABLED, EditContainerGroupField.STARTUP_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.EXEC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.STARTUP_PROBE_COMMAND_ARGUMENTS]: array().of(
            object().shape({ argument: string().required() }),
          ),
          [EditContainerGroupField.STARTUP_PROBE_ENABLED]: boolean(),
          [EditContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD]: number()
            .min(0)
            .max(10000)
            .required()
            .when(EditContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.STARTUP_PROBE_HEADERS]: array()
            .of(
              object().shape({
                name: string()
                  .required(intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.nameRequiredErrorText))
                  .max(128, intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
                value: string()
                  .required(intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.valueRequiredErrorText))
                  .max(128, intl.formatMessage(EditHealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
              }),
            )
            .when([EditContainerGroupField.STARTUP_PROBE_ENABLED, EditContainerGroupField.STARTUP_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(EditContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.STARTUP_PROBE_PATH]: string()
            .required(intl.formatMessage(EditHealthProbeComponentMessages.pathRequiredText))
            .when([EditContainerGroupField.STARTUP_PROBE_ENABLED, EditContainerGroupField.STARTUP_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
              then: (schema) =>
                schema
                  .required(intl.formatMessage(EditHealthProbeComponentMessages.pathRequiredText))
                  .matches(/^\/.*/, intl.formatMessage(EditHealthProbeComponentMessages.pathInvalidMessage)),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(EditContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.STARTUP_PROBE_PORT]: number()
            .min(1)
            .max(65535)
            .required()
            .when([EditContainerGroupField.STARTUP_PROBE_ENABLED, EditContainerGroupField.STARTUP_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol !== ProtocolOptions.EXEC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.STARTUP_PROBE_PROTOCOL]: string()
            .oneOf(Object.values(ProtocolOptions))
            .required()
            .when(EditContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.STARTUP_PROBE_SERVICE]: string()
            .max(128)
            .required()
            .when([EditContainerGroupField.STARTUP_PROBE_ENABLED, EditContainerGroupField.STARTUP_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.GRPC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD]: number()
            .min(1)
            .max(10000)
            .required()
            .when(EditContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS]: number()
            .min(1)
            .max(10000)
            .required()
            .when(EditContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [EditContainerGroupField.TCP_HOST]: string().when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.TCP,
            then: (schema) =>
              schema
                .required(intl.formatMessage(EditTCPInputFieldsMessages.hostRequiredText))
                .max(1000, intl.formatMessage(EditTCPInputFieldsMessages.hostMaxCharactersText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.TCP_PORT]: number().when(EditContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.TCP,
            then: (schema) => schema.required().min(1).max(65535),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.VCPUS]: number().required(),
          [EditContainerGroupField.QUAY_PASSWORD]: string().when(EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER, {
            is: PrivateRegistryProvider.QUAY_CONTAINER,
            then: (schema) => schema.required(intl.formatMessage(EditQuayInputFieldsMessages.passwordRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [EditContainerGroupField.QUAY_USERNAME]: string().when(EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER, {
            is: PrivateRegistryProvider.QUAY_CONTAINER,
            then: (schema) => schema.required(intl.formatMessage(EditQuayInputFieldsMessages.usernameRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
        },
        [
          [EditContainerGroupField.HTTP_USER, EditContainerGroupField.HTTP_PASSWORD],
          [EditContainerGroupField.HTTP_PASSWORD, EditContainerGroupField.HTTP_PASSWORD],
          [EditContainerGroupField.HTTP_USER, EditContainerGroupField.HTTP_USER],
        ],
      ),
    [intl, maxReplicaCount],
  )
  return validationScheme
}
