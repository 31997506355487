// Auth
export const verifyAccountPageRoutePath = '/verify-account'
export const resetPasswordPageRoutePath = '/reset-password'

// Account
export const accountPageRoutePath = '/account'
export const changePasswordPageRoutePath = '/account/change-password'
export const apiKeyPageRoutePath = '/api-key'
export const changeAPIKeyPageRoutePath = '/api-key/change'

// Organizations
export const organizationsPageRoutePath = '/organizations'
export const createOrganizationPageRoutePath = '/organizations/new'
export const editOrganizationPageRoutePath = '/organizations/:organizationName/edit'
export const webhookSecretKeyRoutePath = '/organizations/:organizationName/webhook-secret-key'
export const teamPageRoutePath = '/organizations/:organizationName/team'
export const inviteTeamMemberPageRoutePath = '/organizations/:organizationName/team/invite'
export const organizationBillingPageRoutePath = '/organizations/:organizationName/billing'
export const noProjectsAvailablePageRoutePath = '/organizations/:organizationName/no-projects'

// Inference Endpoints
export const inferenceEndpointsMarketplacePageRoutePath = '/organizations/:organizationName/inference-endpoints/'
export const inferenceEndpointDetailsPageRoutePath =
  '/organizations/:organizationName/inference-endpoints/:inferenceEndpointName'

// Projects
export const createProjectPageRoutePath = '/organizations/:organizationName/projects/new'
export const selectProductPageRoutePath = '/organizations/:organizationName/projects/:projectName'
export const editProjectPageRoutePath = '/organizations/:organizationName/projects/:projectName/edit'

// Containers
export const containersPageRoutePath = '/organizations/:organizationName/projects/:projectName/containers'
export const containerGroupDetailsPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/containers/:containerGroupName/'
export const createContainerGroupPageRoutePath = '/organizations/:organizationName/projects/:projectName/containers/new'
export const editContainerGroupPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/containers/:containerGroupName/edit'
export const containerGroupLogsPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/containers/:containerGroupName/logs'
export const containerGroupInstanceDetailsPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/containers/:containerGroupName/instances/:instanceId'

// Recipes
export const recipesMarketplacePageRoutePath = '/organizations/:organizationName/projects/:projectName/recipes'
export const createRecipePageRoutePath =
  '/organizations/:organizationName/projects/:projectName/recipes/:recipeName/new'
export const recipeDetailsPageRoutePath = '/organizations/:organizationName/projects/:projectName/recipes/:recipeName'
export const recipeDeploymentsPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/recipe-deployments'
export const recipeDeploymentDetailsPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/recipe-deployments/:recipeDeploymentName'
export const editRecipeDeploymentPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/recipe-deployments/:recipeDeploymentName/edit'
export const recipeDeploymentInstanceDetailsPageRoutePath =
  'organizations/:organizationName/projects/:projectName/recipe-deployments/:recipeDeploymentName/instances/:instanceId'

// Job Queues
export const jobQueuesPageRoutePath = '/organizations/:organizationName/projects/:projectName/job-queues'
export const createJobQueuePageRoutePath = '/organizations/:organizationName/projects/:projectName/job-queues/new'
export const editJobQueuePageRoutePath =
  '/organizations/:organizationName/projects/:projectName/job-queues/:jobQueueName/edit'
export const jobQueueDetailsPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/job-queues/:jobQueueName'
