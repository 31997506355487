import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useIntl } from 'react-intl'
import { DatePickerMessages } from './messages'

export interface DatePickerProps {
  /** Defines the date format used within the input field. */
  dateFormat?: string
  /** Sets End date */
  endDate?: Date | null
  /** A flag indicating if 100% of parent element width should be occupied */
  isFullWidth?: boolean
  /** If true, sets the current date as the selected date when the date picker is initialized. */
  hasCurrentDate?: boolean
  /** Shows error styling */
  hasError?: boolean
  /** Shows a dropdown to select the month directly. */
  hasMonthDropdown?: boolean
  /** Whether to show time selection options. */
  hasTimeSelect?: boolean
  /** The string for the html property of id. */
  id: string
  /** Shows a dropdown to select the year directly. */
  hasYearDropdown?: boolean
  /** Whether the date picker input should display a clear button to remove the selected date. */
  isClearable?: boolean
  /** If set to true, the date picker will be disabled and cannot be interacted with. */
  isDisabled?: boolean
  /** When doing range this tells the date picker what the end is */
  isSelectEnd?: boolean
  /** When doing range this tells the date picker what the start is */
  isSelectStart?: boolean
  /** The text that will show beside the checkbox. */
  label?: string
  /** The latest date that can be selected. */
  maxDate?: Date
  /** The earliest date that can be selected. */
  minDate?: Date
  /** The number of calendar months to show at once. */
  monthsShown?: number
  /** Function called when the date is changed. */
  onChange: (date: Date | null) => void
  /** Placeholder text for the input field when no date is selected. */
  placeholderText?: string
  /** Sets Start Date. */
  startDate?: Date | null
  /** Adds a "Today" button to the calendar for selecting the current date. */
  todayButton?: string
}

export const DatePicker: FunctionComponent<DatePickerProps> = ({
  dateFormat,
  endDate = null,
  hasCurrentDate = false,
  hasError = false,
  hasMonthDropdown = false,
  hasTimeSelect = true,
  hasYearDropdown = false,
  id,
  isClearable = false,
  isDisabled = false,
  isFullWidth = false,
  isSelectEnd = false,
  isSelectStart = false,
  label,
  maxDate = new Date(),
  minDate,
  monthsShown = 1,
  onChange,
  placeholderText,
  startDate = null,
  todayButton,
}) => {
  const intl = useIntl()
  const [selectedDate, setSelectedDate] = useState<Date | null>(hasCurrentDate ? new Date() : null)

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
    if (onChange) {
      onChange(date)
    }
  }

  useEffect(() => {
    if (startDate && endDate) {
      if (isSelectStart && !isSelectEnd) {
        setSelectedDate(startDate)
      } else if (!isSelectStart && isSelectEnd) {
        setSelectedDate(endDate)
      }
    }
  }, [startDate, endDate, isSelectStart, isSelectEnd])

  const formattedDateFormat = dateFormat ? dateFormat : hasTimeSelect ? 'MM/dd/yyyy HH:mm:ss' : 'MM/dd/yyyy'

  return (
    <div className={isFullWidth ? 'flex w-full flex-col justify-start' : ''} id={id}>
      {label && (
        <label
          className={classNames('ml-1 block text-base', {
            'text-red-70': hasError,
          })}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <ReactDatePicker
        calendarClassName="text-green-90 rounded-md border-green-90 bg-transparent-green-90"
        className={classNames('flex w-full rounded-md border p-2', {
          'w-full': isFullWidth,
          'border-red-70 text-red-70': hasError,
          'border-neutral-60 bg-neutral-40': isDisabled,
        })}
        dateFormat={formattedDateFormat}
        dayClassName={() => `text-green-90`}
        disabled={isDisabled}
        endDate={endDate}
        isClearable={isClearable}
        maxDate={maxDate}
        minDate={minDate}
        monthsShown={monthsShown}
        onChange={handleDateChange}
        placeholderText={placeholderText ?? intl.formatMessage(DatePickerMessages.defaultPlaceholderText)}
        selected={selectedDate}
        selectsEnd={isSelectEnd}
        selectsStart={isSelectStart}
        showMonthDropdown={hasMonthDropdown}
        showTimeSelect={hasTimeSelect}
        showYearDropdown={hasYearDropdown}
        startDate={startDate}
        timeClassName={() => `text-green-90`}
        todayButton={todayButton}
      />
    </div>
  )
}
