import { ContainerGroupPriority } from '@saladtechnologies/openapi-cloud-portal-browser'
import { useEffect, useState, type FunctionComponent } from 'react'
import type { Control, FieldValues, UseFormResetField } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from '../../../../components/base'
import { Select } from '../../../../components/forms/Select'
import { EditPriorityInputMessages } from '../../messages'
import type { EditContainerGroupFormSectionIdAttributes, EditContainerGroupValues } from '../../models'
import { EditContainerGroupField } from '../../models'

interface EditPriorityInputProps {
  /** The control for the react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The current priority value. */
  currentPriority: ContainerGroupPriority | undefined
  /** The id attribute for the field. */
  id: EditContainerGroupFormSectionIdAttributes.PRIORITY
  /** The flag indicating if any GPU options are selected */
  isGPUSelected: boolean
  /** The react hook form method that provides the ability to reset a specified field value. */
  resetField: UseFormResetField<FieldValues>
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: any
}

export const EditPriorityInput: FunctionComponent<EditPriorityInputProps> = ({
  control,
  currentPriority,
  id,
  isGPUSelected,
  resetField,
  setValue,
}) => {
  const intl = useIntl()
  const [localPriority, setPriority] = useState<ContainerGroupPriority | undefined>(currentPriority)

  useEffect(() => {
    if (isGPUSelected) {
      const value = localPriority !== undefined ? localPriority : ContainerGroupPriority.High
      setValue(EditContainerGroupField.PRIORITY, value)
      setPriority(value)
    } else {
      resetField(EditContainerGroupField.PRIORITY)
      setPriority(undefined)
    }
  }, [isGPUSelected, localPriority, resetField, setValue])

  return (
    <div id={id} className="my-12 flex">
      <div>
        <p className="text-base font-bold">{intl.formatMessage(EditPriorityInputMessages.header)}</p>
        <p className="mb-2">
          {intl.formatMessage(EditPriorityInputMessages.descriptionPartOne, {
            learn_more: (
              <Link url="https://docs.salad.com/container-engine/priority-pricing">
                {intl.formatMessage(EditPriorityInputMessages.learMoreLinkText)}
              </Link>
            ),
          })}
        </p>
        <p>{intl.formatMessage(EditPriorityInputMessages.descriptionPartTwo)}</p>
      </div>
      {isGPUSelected ? (
        <Controller
          name={EditContainerGroupField.PRIORITY}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              {...fieldState}
              defaultSelectedValue={field.value}
              hideLabelText
              isDisabled={!isGPUSelected}
              isPlaceholderOptionDisabled
              labelText={intl.formatMessage(EditPriorityInputMessages.label)}
              onChange={(value) => {
                field.onChange(value)
              }}
              options={[
                {
                  label: intl.formatMessage(EditPriorityInputMessages.highPriorityLabel),
                  value: ContainerGroupPriority.High,
                },
                {
                  label: intl.formatMessage(EditPriorityInputMessages.mediumPriorityLabel),
                  value: ContainerGroupPriority.Medium,
                },
                {
                  label: intl.formatMessage(EditPriorityInputMessages.lowPriorityLabel),
                  value: ContainerGroupPriority.Low,
                },
                {
                  label: intl.formatMessage(EditPriorityInputMessages.batchPriorityLabel),
                  value: ContainerGroupPriority.Batch,
                },
              ]}
            />
          )}
        />
      ) : (
        <Select
          defaultSelectedValue={ContainerGroupPriority.Batch}
          hideLabelText
          isDisabled
          isPlaceholderOptionDisabled
          labelText={intl.formatMessage(EditPriorityInputMessages.label)}
          placeholderText={intl.formatMessage(EditPriorityInputMessages.batchPriorityLabel)}
          onChange={() => {}}
          options={[
            {
              label: intl.formatMessage(EditPriorityInputMessages.batchPriorityLabel),
              value: ContainerGroupPriority.Batch,
            },
          ]}
        />
      )}
    </div>
  )
}
