import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectApiKey } from '../../features/apiKey/apiKeySelectors'
import { getApiKey, updateApiKey } from '../../features/apiKey/apiKeySlice'
import { ExpirationDelayOptionInSeconds } from '../../features/apiKey/models'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { changeAPIKeyPageRoutePath } from '../../routes/routePaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { ChangeAPIKeyPage } from './ChangeAPIKeyPage'

export const ConnectedChangeAPIKeyPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const apiKey = useAppSelector(selectApiKey)
  const isGetAPIKeyRequestPending = useAppSelector((state) => selectRequestStatusIsPending(state, 'getApiKey'))
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isUpdateAPIKeyRequestPending = useAppSelector((state) => selectRequestStatusIsPending(state, 'updateApiKey'))

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onUpdateApiKey = useCallback(
    (expirationDelay?: ExpirationDelayOptionInSeconds) => {
      dispatch(updateApiKey({ expirationDelay }))
    },
    [dispatch],
  )

  const onGetApiKey = useCallback(() => {
    dispatch(getApiKey())
  }, [dispatch])

  useEffect(() => {
    onGetApiKey()
    dispatch(trackMixpanelPageViewedEvent({ path: changeAPIKeyPageRoutePath }))
  }, [dispatch, onGetApiKey])

  return isGetAPIKeyRequestPending ? (
    <LoadingPage />
  ) : (
    <ChangeAPIKeyPage
      currentAPIKey={apiKey}
      isChangeAPIKeyRequestPending={isUpdateAPIKeyRequestPending}
      isLeftColumnOpen={isLeftColumnOpen}
      onChangeAPIKey={onUpdateApiKey}
      onCloseLeftDrawer={handleCloseLeftNavBar}
    />
  )
}
