import { defineMessages } from 'react-intl'

export const WebhookSecretKeyMainContentMessages = defineMessages({
  copyWebhookSecretKeyButtonLabel: {
    defaultMessage: 'Copy Webhook Secret Key',
    id: 'WHGRX8sm',
    description: 'The label for the copy Webhook Secret Key button.',
  },
  currentWebhookSecretKeyLabel: {
    defaultMessage: 'Current Webhook Secret Key',
    id: 'BmCIheOZ',
    description: 'The label for the current Webhook Secret Key text field.',
  },
  generateWebhookSecretKeyButtonLabel: {
    defaultMessage: 'Change Webhook Secret Key',
    id: 'CAPXAWZU',
    description: 'The label for the Change WebhookSecret Key button.',
  },
  title: {
    defaultMessage: 'Webhook Secret Key',
    id: '87tsPZLH',
    description: 'The title for the WebhookSecret Key page.',
  },
})
