import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface webhookSecretKeyState {
  webhookSecretKey: string
}

const initialState: webhookSecretKeyState = {
  webhookSecretKey: '',
}

export const webhookSecretKeySlice = createSlice({
  name: 'webhookSecretKey',
  initialState,
  reducers: {
    getWebhookSecretKey(_state, _action: PayloadAction<{ organizationName: string }>) {},
    setWebhookSecretKey(state, action: PayloadAction<{ webhookSecretKey: string }>) {
      state.webhookSecretKey = action.payload.webhookSecretKey
    },
    updateWebhookSecretKey(_state, _action: PayloadAction<{ organizationName: string }>) {},
  },
})

export const { getWebhookSecretKey, setWebhookSecretKey, updateWebhookSecretKey } = webhookSecretKeySlice.actions
