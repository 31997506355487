import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { WebhookSecretKeyMainContent } from './components/WebhookSecretKeyMainContent'

export interface WebhookSecretKeyPageProps {
  /** The flag indicating that the request to change the Webhook Secret key is pending. */
  isChangeWebhookSecretKeyRequestPending: boolean
  /** The flag indicating that the request to change the Webhook Secret key was successful. */
  isChangeWebhookSecretKeyRequestSuccessful?: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user clicks on the Change Webhook Secret Key button. */
  onChangeWebhookSecretKey: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The Webhook Secret Key. */
  webhookSecretKey: string
}

export const WebhookSecretKeyPage: FunctionComponent<WebhookSecretKeyPageProps> = ({
  isChangeWebhookSecretKeyRequestPending,
  isChangeWebhookSecretKeyRequestSuccessful,
  isLeftColumnOpen,
  onChangeWebhookSecretKey,
  onCloseLeftDrawer,
  webhookSecretKey,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar />}
      MainColumn={
        <WebhookSecretKeyMainContent
          webhookSecretKey={webhookSecretKey}
          isChangeWebhookSecretKeyRequestPending={isChangeWebhookSecretKeyRequestPending}
          isChangeWebhookSecretKeyRequestSuccessful={isChangeWebhookSecretKeyRequestSuccessful}
          onChangeWebhookSecretKey={onChangeWebhookSecretKey}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
