import type {
  ContainerGroup,
  ContainerGroupLogsItemsInner,
  ContainerGroupLogsQuery,
  RamOption,
  StorageOption,
  WorkloadError,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useState } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import type { DeploymentInstance } from '../../features/containerGroupInstanceTable/models'
import { ClientGpuClass } from '../../features/gpuClasses/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { BillingInformationMissingModal } from '../CreateContainerGroup/components/BillingInformationMissingModal'
import { ContainerGroupQuotaExceededModal } from '../CreateContainerGroup/components/ContainerGroupQuotaExceededModal'
import { ContainerGroupDetailsMainContent } from './components/ContainerGroupDetailsMainContent'
import { DeleteContainerGroupModal } from './components/DeleteContainerGroupModal'
import type { ContainerGroupLogsFormValues } from './models'

export interface ContainerGroupDetailsPageProps {
  /** Container Group to be displayed on the page */
  containerGroup: ContainerGroup
  /** The path to container group details page */
  containerGroupDetailsPagePath: string
  /** The Container Group instances */
  containerGroupInstances: DeploymentInstance[]
  /** The path to navigate to container groups page */
  containerGroupsPagePath: string
  /** The create container group page path */
  createContainerGroupPagePath: string
  /** The container log array of items */
  containerGroupLogs: ContainerGroupLogsItemsInner[]
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The default form values for the container group logs query. */
  defaultContainerGroupLogsFormValues?: Partial<ContainerGroupLogsFormValues>
  /** The default tab index for the details page to open on. */
  defaultTabIndex: number
  /** The path to navigate to edit container groups page */
  editContainerGroupsPagePath: string
  /** The detailed data of the GPU classes */
  gpuClasses?: ClientGpuClass[]
  /** The flag indicating the billing information missing modal showing state */
  isBillingInformationMissingModalShowing: boolean
  /** The flag indicating the container group deployment exceeded modal showing state */
  isContainerGroupDeploymentQuotaExceededModalShowing: boolean
  /** The value indicates whether delete request is in progress (waiting for response) */
  isDeletingContainerGroupRequestPending: boolean
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating that the get container group logs request is pending. */
  isGetContainerGroupLogsPending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** Indicates whether refresh button is in loading state */
  isRefreshButtonLoading: boolean
  /** Indicates that start container group request is currently in progress. */
  isStartContainerGroupRequestPending: boolean
  /** Indicates that stop container group request is currently in progress. */
  isStopContainerGroupRequestPending: boolean
  /** The link for users to request an increase to their container groups quota */
  linkToRequestIncreaseForContainerGroupQuotas: string
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /** The callback executed when the user clicks on "Delete" (delete container group) button in the modal window */
  onDeleteContainerGroup: () => void
  /** The callback executed when the user clicks on "Duplicate" button. */
  onDuplicateContainerGroup: () => void
  /** The callback executed when the user needs to enter their billing information. */
  onEnterBillingInformation: () => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /** The callback executed when the user clicks the `Query` button on container logs. */
  onQueryContainerGroupLogs: (containerGroupLogsQuery: ContainerGroupLogsQuery) => void
  /**
   * The callback executed when the user reallocates a container group instance.
   *
   * @param machineId The machine ID of the container group instance to reallocate.
   */
  onReallocateContainerGroupInstance: (machineId: string) => void
  /**
   * A callback that when executed records a mixpanel event
   *
   * @param label The label of the element that was clicked
   */
  onTrackMixpanelEvent: (label: string, machineId?: string) => void
  /**
   * The callback executed when the user recreates a container group instance.
   *
   * @param machineId The machine ID of the container group instance to reallocate.
   */
  onRecreateContainerGroupInstance: (machineId: string) => void
  /** The callback executed when the user clicks on "Refresh" link */
  onRefreshContainerGroup: () => void
  /**
   * The callback executed when the user restarts a container group instance.
   *
   * @param machineId The machine ID of the container group instance to reallocate.
   */
  onRestartContainerGroupInstance: (machineId: string) => void
  /** The callback executed when there is an update to the billing information required modal showing state */
  onSetBillingInformationMissingModalShowingState: (showing: boolean) => void
  /** The callback executed when there is an update to the container group deployments exceeded modal showing state */
  onSetContainerGroupDeploymentsQuotaExceededModalShowingState: (showing: boolean) => void
  /**
   * Sets the default form values to be applied to the Container Logs Query Form.
   *
   * @param formValues The container group logs form values.
   */
  onSetDefaultContainerLogsFormValues: (formValues: Partial<ContainerGroupLogsFormValues>) => void
  /** The callback executed when the user clicks the `Start` button. */
  onStartContainerGroup: () => void
  /** The callback executed when the user clicks the `Pause` button. */
  onStopContainerGroup: () => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
  /** The workload errors for the container group. */
  workloadErrors: WorkloadError[]
}

export const ContainerGroupDetailsPage: FunctionComponent<ContainerGroupDetailsPageProps> = ({
  containerGroup,
  containerGroupDetailsPagePath,
  containerGroupInstances,
  containerGroupLogs,
  containerGroupsPagePath,
  createContainerGroupPagePath,
  currentOrganization,
  currentProject,
  defaultContainerGroupLogsFormValues,
  defaultTabIndex,
  editContainerGroupsPagePath,
  gpuClasses,
  isBillingInformationMissingModalShowing,
  isContainerGroupDeploymentQuotaExceededModalShowing,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isDeletingContainerGroupRequestPending,
  isGetContainerGroupLogsPending,
  isLeftColumnOpen,
  isRefreshButtonLoading,
  isStartContainerGroupRequestPending,
  isStopContainerGroupRequestPending,
  linkToRequestIncreaseForContainerGroupQuotas,
  onCloseLeftDrawer,
  onDeleteContainerGroup,
  onDeleteProject,
  onDuplicateContainerGroup,
  onEnterBillingInformation,
  onProjectChange,
  onQueryContainerGroupLogs,
  onReallocateContainerGroupInstance,
  onRecreateContainerGroupInstance,
  onRefreshContainerGroup,
  onRestartContainerGroupInstance,
  onSetBillingInformationMissingModalShowingState,
  onSetContainerGroupDeploymentsQuotaExceededModalShowingState,
  onSetDefaultContainerLogsFormValues,
  onStartContainerGroup,
  onStopContainerGroup,
  onTrackMixpanelEvent,
  projects,
  ramOptions,
  storageOptions,
  workloadErrors,
}) => {
  const [isDeleteContainerModalShown, setIsDeleteContainerModalShown] = useState(false)
  const handleCloseDeleteContainerGroupModal = useCallback(
    () => setIsDeleteContainerModalShown(false),
    [setIsDeleteContainerModalShown],
  )
  const handleOpenDeleteContainerGroupModal = useCallback(
    () => setIsDeleteContainerModalShown(true),
    [setIsDeleteContainerModalShown],
  )

  return (
    <>
      <ThreeColumnLayout
        isLeftColumnOpen={isLeftColumnOpen}
        LeftColumn={
          <LeftNavigationBar
            currentOrganization={currentOrganization}
            currentProject={currentProject}
            isDeleteProjectPending={isDeleteProjectPending}
            isDeleteProjectSuccessful={isDeleteProjectSuccessful}
            onDeleteProject={onDeleteProject}
            onProjectChange={onProjectChange}
            projects={projects}
          />
        }
        MainColumn={
          <ContainerGroupDetailsMainContent
            containerGroup={containerGroup}
            containerGroupDetailsPagePath={containerGroupDetailsPagePath}
            containerGroupInstances={containerGroupInstances}
            containerGroupsPagePath={containerGroupsPagePath}
            containerGroupLogs={containerGroupLogs}
            createContainerGroupPagePath={createContainerGroupPagePath}
            defaultContainerGroupLogsFormValues={defaultContainerGroupLogsFormValues}
            defaultTabIndex={defaultTabIndex}
            editContainerGroupsPagePath={editContainerGroupsPagePath}
            gpuClasses={gpuClasses}
            isGetContainerGroupLogsPending={isGetContainerGroupLogsPending}
            isRefreshButtonLoading={isRefreshButtonLoading}
            isStartContainerGroupRequestPending={isStartContainerGroupRequestPending}
            isStopContainerGroupRequestPending={isStopContainerGroupRequestPending}
            onDeleteContainerGroup={handleOpenDeleteContainerGroupModal}
            onDuplicateContainerGroup={onDuplicateContainerGroup}
            onRefreshContainerGroup={onRefreshContainerGroup}
            onQueryContainerGroupLogs={onQueryContainerGroupLogs}
            onReallocateContainerGroupInstance={onReallocateContainerGroupInstance}
            onTrackMixpanelEvent={onTrackMixpanelEvent}
            onRecreateContainerGroupInstance={onRecreateContainerGroupInstance}
            onRestartContainerGroupInstance={onRestartContainerGroupInstance}
            onSetDefaultContainerLogsFormValues={onSetDefaultContainerLogsFormValues}
            onStartContainerGroup={onStartContainerGroup}
            onStopContainerGroup={onStopContainerGroup}
            ramOptions={ramOptions}
            storageOptions={storageOptions}
            workloadErrors={workloadErrors}
          />
        }
        onCloseLeftDrawer={onCloseLeftDrawer}
      />
      {isDeleteContainerModalShown && (
        <DeleteContainerGroupModal
          containerGroupDisplayName={containerGroup.displayName}
          onCloseWindow={handleCloseDeleteContainerGroupModal}
          onDeleteContainerGroup={onDeleteContainerGroup}
          isDeletingContainerGroupRequestPending={isDeletingContainerGroupRequestPending}
        />
      )}
      {isBillingInformationMissingModalShowing && (
        <BillingInformationMissingModal
          onCloseWindow={() => onSetBillingInformationMissingModalShowingState(false)}
          onEnterBillingInformation={onEnterBillingInformation}
        />
      )}
      {isContainerGroupDeploymentQuotaExceededModalShowing && (
        <ContainerGroupQuotaExceededModal
          linkToRequestIncreaseForContainerGroupQuotas={linkToRequestIncreaseForContainerGroupQuotas}
          onCloseWindow={() => onSetContainerGroupDeploymentsQuotaExceededModalShowingState(false)}
        />
      )}
    </>
  )
}
